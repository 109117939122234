import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorageService } from '../_services/token-storage.service';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {

    constructor(
        private token: TokenStorageService,
        private dialog: MatDialog,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error) => {
                    if (error instanceof TimeoutError) {
                        console.error("Délai d'attente dépassé", error);
                        return throwError(() => new Error('Timeout Exception'));
                    }
                    if (error.status === 401) {
                        this.token.signOut();
                        this.dialog.open(SuccessDialogComponent, {
                            data: {
                                message: 'Votre session a expiré, veuillez vous reconnecter.'
                            }
                        });
                        window.location.href="connexion";
                        return throwError(() => new Error('Unauthorized'));
                    }

                    if (error.error instanceof ErrorEvent) {
                        console.error('Une erreur est survenue côté client.', error);
                    } else {
                        let msgTechnique = error?.error?.detail?.split('with detail')[1];
                        msgTechnique = msgTechnique ? msgTechnique?.replace(/"/g, '') : 'Une erreur technique est survenue côté serveur.';
                        console.error('Une erreur est survenue côté serveur.', msgTechnique, error);
                    }

                    return throwError(() => error);
                })
            )
    }
}

export const httpErrorHandlerProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerInterceptor, multi: true }
];
