import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Article } from '../z-model/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  private apiUrl = environment.baseUrl + 'api/articles';

  constructor(private _http: HttpClient) { }

  getArticles(): Observable<Article[]> {
    return this._http.get<Article[]>(this.apiUrl);
  }

  addArticle(article: Article): Observable<Article> {
    return this._http.post<Article>(this.apiUrl, article);
  }

  updateArticle(article: Article): Observable<Article> {
    return this._http.put<Article>(this.apiUrl, article);
  }

  addArticles(articles: Article[], idModele: string): Observable<Article[]> {
    articles.forEach(element => {
      element.modele.id = idModele;
    });
    let listArticles: Article[] = [...articles];
    return this._http.post<Article[]>(this.apiUrl + '/bulk', listArticles);
  }

  deleteArticle(id: string): Observable<void> {
    return this._http.delete<void>(this.apiUrl + '/' + id);
  }
}
