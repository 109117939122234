<div class="login-container">
    <div class="login-modal">
        <h2>Connexion</h2>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username">Identifiant</label>
                <input type="text" id="username" formControlName="username" required />
            </div>
            <div class="form-group">
                <label for="password">Mot de passe</label>
                <input type="password" id="password" formControlName="password" required />
            </div>
            <button type="submit" [disabled]="!loginForm.valid">Se connecter</button>
        </form>
    </div>
</div>