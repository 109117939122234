import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrl: './pdf-viewer-dialog.component.css'
})
export class PdfViewerDialogComponent {
  constructor(public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { pdfUrl: SafeResourceUrl; fileName: string, onCancel?: () => void  }) {}

  onClose(): void {
    if (this.data.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }
}
