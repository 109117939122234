import { Component, Inject } from '@angular/core';
import { Categorie } from '../../z-model/categorie';
import { CategorieService } from '../../_services/categorie.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';

@Component({
  selector: 'app-modification-categorie',
  templateUrl: './modification-categorie.component.html',
  styleUrl: './modification-categorie.component.css'
})
export class ModificationCategorieComponent {
  nouveauPrix!: number;

  constructor(
    public dialogRef: MatDialogRef<ModificationCategorieComponent>,
    @Inject(MAT_DIALOG_DATA) public categorie: Categorie,
    private _categorieService: CategorieService,
    public dialog: MatDialog,
  ) { }

  updatePrice() {
    this.categorie.prixParGramme = this.nouveauPrix;
    this._categorieService.updateCategorie(this.categorie).subscribe({
      next: () => {
        this.dialog.open(SuccessDialogComponent, {
          data: {
            message: 'Catégorie modifiée avec succès.'
          }
        });
      },
      error: (err) => {
        this.dialog.open(SuccessDialogComponent, {
          data: {
            message: 'Une erreur s\'est produite lors de la modification de la catégorie. La catégorie n\'a pas été modifiée.'
          }
        });
      }
    });
    this.dialogRef.close(this.categorie);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
