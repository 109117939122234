import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Utilisateur } from '../../z-model/utilisateur';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UtilisateurService } from '../../_services/utilisateur.service';
import { RoleService } from '../../_services/role.service';
import { RoleUtilisateur } from '../../z-model/role-utilisateur';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-utilisateur-list',
  templateUrl: './utilisateur-list.component.html',
  styleUrl: './utilisateur-list.component.css'
})
export class UtilisateurListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['nom', 'prenom', 'magasin', 'username', 'email', 'estActif', 'actions'];
  utilisateurs: Utilisateur[] = [];
  dataSource = new MatTableDataSource<Utilisateur>(this.utilisateurs);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  rolesUtilisateurs: RoleUtilisateur[] = [];
  @ViewChild(MatSort) sort!: MatSort;
  searchPrenomUtilisateur!: string;
  searchEmailUtilisateur!: string;
  searchEtatUtilisateur!: string;
  searchNomUtilisateur!: string;
  searchUsername!: string;
  utilisateurIdConnecte !: string;

  constructor(
    private _utilisateurService: UtilisateurService,
    private _liveAnnouncer: LiveAnnouncer,
    private _rolesService: RoleService,
    private dialog: MatDialog,
    private _router: Router,
    private tokenStorage: TokenStorageService,
  ) { }

  ngOnInit(): void {
    this.loadRoleUtilisateur();
    this.loadUtilisateurs();
    this.utilisateurIdConnecte = this.tokenStorage.getUser().Utilisateur.id;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.utilisateurs;
    this.dataSource.sort = this.sort;
  }

  loadUtilisateurs() {
    this._utilisateurService.getUtilisateurs().subscribe(utilisateurs => {
      this.utilisateurs = utilisateurs;
      this.dataSource.data = utilisateurs;
      this.dataSource.paginator = this.paginator;
    });
  }
  
  loadRoleUtilisateur() {
    this._rolesService.getRoles().subscribe(data => {
      this.rolesUtilisateurs = data;
    });
  }

  filterUtilisateurs(): void {
    this.dataSource.data = this.utilisateurs.filter(utilisateur => {
      const etatFiltre = this.searchEtatUtilisateur === "" ? null : JSON.parse(this.searchEtatUtilisateur);
      return (
        (!this.searchNomUtilisateur || utilisateur.nom.toLowerCase().includes(this.searchNomUtilisateur.toLowerCase())) &&
        (!this.searchPrenomUtilisateur || utilisateur.prenom.toLowerCase().includes(this.searchPrenomUtilisateur.toLowerCase())) &&
        (!this.searchUsername || utilisateur.username.toLowerCase().includes(this.searchUsername.toLowerCase())) &&
        (!this.searchEmailUtilisateur || utilisateur.email.toLowerCase().includes(this.searchEmailUtilisateur.toLowerCase())) &&
        (etatFiltre === null || utilisateur.estActif === this.searchEtatUtilisateur)
      );
    });
    this.dataSource.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  openUtilisateurForm(utilisateur?: Utilisateur) {
    if (utilisateur) {
      this._router.navigate(['/utilisateur/' + utilisateur.id]);
    } else {
      this._router.navigate(['/utilisateur/new']);
    }
  }

  changerEtatProfile(utilisateur: Utilisateur) {
    this._utilisateurService.changerEtatProfile(utilisateur).subscribe(utilisateur => {
      this.loadUtilisateurs();
      this.dialog.open(SuccessDialogComponent, {
        data: {
          message: 'Le changement de l\'etat est pris en compte'
        }
      });
    });    
  }
}
