import { PaiementStatistiques } from '../z-model/paiement-statistiques';
import { SousCategorieService } from '../_services/sous-categorie.service';
import { ModePayementService } from '../_services/mode-payement.service';
import { StatistiquesService } from '../_services/statistiques.service';
import { CategorieService } from '../_services/categorie.service';
import { MagasinService } from '../_services/magasin.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SousCategorie } from '../z-model/sous-categorie';
import { ModePayement } from '../z-model/mode-payement';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Categorie } from '../z-model/categorie';
import { Magasin } from '../z-model/magasin';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-statistiques-payements',
  templateUrl: './statistiques-payements.component.html',
  styleUrl: './statistiques-payements.component.css'
})
export class StatistiquesPayementsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['dateOperation', 'totalPayements', 'totalDepenses', 'delta'];
  listPaiementStatistiques: PaiementStatistiques[] = [];
  dataSource = new MatTableDataSource <PaiementStatistiques>(this.listPaiementStatistiques);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  sousCategories: SousCategorie[] = [];
  @ViewChild(MatSort) sort!: MatSort;
  modesPaiement: ModePayement[] = [];
  noResultsField: boolean = false;
  listeMagasins: Magasin[] = [];
  categories: Categorie[] = [];
  searchForm!: FormGroup;
  totalPayements: number = 0;
  totalDepenses: number = 0;
  totalCaisses: number = 0;
  noResults!: string;

  constructor(
    private _sousCategoriesService: SousCategorieService,
    private _modePayementService: ModePayementService,
    private _statistiquesService: StatistiquesService,
    private _categorieService: CategorieService,
    private _magasinService: MagasinService,
    private fb: FormBuilder,
  ) {
    this.loadListeModePaiement();
    this.loadSousCategories();
    this.loadListeMagasins();
    this.creatSearchForm();
    this.loadCategories();
  }

  ngOnInit(): void {
    this.onSubmit();
  }

  loadListeModePaiement() {
    this._modePayementService.getModePayement().subscribe(modes => {
      this.modesPaiement = modes;
    });
  }

  loadSousCategories() {
    this._sousCategoriesService.getSousCategories().subscribe(data => {
      this.sousCategories = data;
    });
  }

  loadListeMagasins() {
    this._magasinService.getMagasinsVisibles().subscribe(magasins => {
      this.listeMagasins = magasins;
    });
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  creatSearchForm() {
    this.searchForm = this.fb.group({
      minDate: '',
      maxDate: '',
      categorie: '',
      sousCategorie: '',
      modePaiementId: '',
      magasinId: '',
    });
  }

  reinitialiser(){
    this.searchForm.reset();
    this.listPaiementStatistiques = [];
    this.noResultsField = false;
    this.noResults = "";
  }

  onSubmit() {
    this.noResultsField = false;
    this.noResults = "";
    this._statistiquesService.getPaiementStatistiques(this.searchForm.value).subscribe(paiementsStatistiques => {
      this.listPaiementStatistiques = paiementsStatistiques;
      this.dataSource.data = paiementsStatistiques;
      this.totalPayements = Number(paiementsStatistiques.reduce((acc, commandeStatistique) => acc + commandeStatistique.totalPayements, 0).toFixed(2));
      this.totalDepenses = Number(paiementsStatistiques.reduce((acc, commandeStatistique) => acc + commandeStatistique.totalDepenses, 0).toFixed(2));
      this.totalCaisses = Number(paiementsStatistiques.reduce((acc, commandeStatistique) => acc + commandeStatistique.delta, 0).toFixed(2));
      
      if (paiementsStatistiques.length === 0) {
        this.noResultsField = true;
        this.noResults = "Aucun résultat ne correspond à votre recherche.";
      }

      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.listPaiementStatistiques;
    this.dataSource.sort = this.sort;
  }
}
