<div class="list-container">
    <p class="app-titre-component">Gestion des articles</p>

    <div class="container">
        <!-- Champ de recherche par référence -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchRefQuery" (input)="filterArticles()" placeholder="Référence de l'artcile"/>
        </mat-form-field>
        <!-- Champ de recherche par nom du modèle -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchQuery" (input)="filterArticles()" placeholder="Nom du modèle"/>
        </mat-form-field>
        <!-- Filtrage par statut -->
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="selectedEtat" (selectionChange)="filterArticles()">
                <mat-option value="">Tout les critères</mat-option>
                <mat-option *ngFor="let statutArticle of statutsArticle" [value]="statutArticle.id">{{ statutArticle.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Filtrage par catégorie -->
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="selectedCategory" (selectionChange)="filterArticles()">
                <mat-option value="">Toutes les catégories</mat-option>
                <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Filtrage par sous catégorie -->
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="selectedSousCategory" (selectionChange)="filterArticles()">
                <mat-option value="">Toutes les sous catégories</mat-option>
                <mat-option *ngFor="let sousCategory of sousCategories" [value]="sousCategory.id">{{ sousCategory.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Bouton pour ajouter un nouvel article -->
        <button mat-raised-button class="uniform-size" (click)="openArticleForm(null)" *ngIf="user.Utilisateur?.role.name === Role.Admin">Ajouter un article</button>
    </div>
    <div>
        Total d'article(s) : <strong>{{ totalArticles }}</strong>, avec un poids total de <strong>{{ totalPoids }}</strong> g et un prix total de <strong>{{ totalPrix }}</strong> DH
    </div>
    <!-- Liste d'articles sous forme de tableau -->
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table">
        <ng-container matColumnDef="ref">
            <mat-header-cell *matHeaderCellDef> Référence </mat-header-cell>
            <mat-cell *matCellDef="let article">
                <span (click)="openArticleMvtsDialog(article)" class="reference-link"> {{ article.reference }} </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nomModel">
            <mat-header-cell *matHeaderCellDef> Nom modèle </mat-header-cell>
            <mat-cell *matCellDef="let article"> {{ article.modele.nomModel }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="etat">
            <mat-header-cell *matHeaderCellDef> Site </mat-header-cell>
            <mat-cell *matCellDef="let article"> {{ article.statut.libelle }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="poids">
            <mat-header-cell *matHeaderCellDef> Poids </mat-header-cell>
            <mat-cell *matCellDef="let article"> {{ article.poids }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="prix">
            <mat-header-cell *matHeaderCellDef> Prix </mat-header-cell>
            <mat-cell *matCellDef="let article"> {{ article.prix }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="carats">
            <mat-header-cell *matHeaderCellDef> Caracts </mat-header-cell>
            <mat-cell *matCellDef="let article"> {{ article.caracts }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions" *ngIf="user.Utilisateur?.role.name === Role.Admin">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let article">
                <button *ngIf="article.statut.libelle != 'Vendu'"  mat-icon-button (click)="openArticleForm(article)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="article.statut.libelle != 'Vendu'" mat-icon-button (click)="supprimerArticle(article.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>