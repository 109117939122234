import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Payement } from '../z-model/payement';

@Injectable({
  providedIn: 'root'
})
export class PayementService {

  apiUrl = environment.baseUrl + 'api/payements';

  constructor(private _http: HttpClient) { }

  addPayement(paiements: Payement[]): Observable<Payement[]> {
    let payements: Payement[] = [...paiements];
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this._http.post<Payement[]>(this.apiUrl + '/bulk', JSON.stringify(payements), { headers: headers });
  }

  getPayement(idCommande: string): Observable<Payement[]> {
    return this._http.get<Payement[]>(this.apiUrl + '/commande/' + idCommande);
  }

  getRecuPayement(idCommande: string) {
    return this._http.get(this.apiUrl + '/genererRecuPayement/' + idCommande, {
      responseType: 'blob'
    });
  }
}
