import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Utilisateur } from '../z-model/utilisateur';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  apiUrl = environment.baseUrl + 'api/utilisateurs';

  constructor(private _http: HttpClient) { }

  getUtilisateurs(): Observable<Utilisateur[]> {
    return this._http.get<Utilisateur[]>(this.apiUrl);
  }

  getUtilisateur(idUtilisateur: string): Observable<Utilisateur> {
    return this._http.get<Utilisateur>(this.apiUrl + '/' + idUtilisateur);
  }

  addUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur> {
    return this._http.post<Utilisateur>(this.apiUrl, JSON.stringify(utilisateur), { headers: headers });
  }

  modifierMDPUtilisateur(changePassword: any): Observable<Utilisateur> {
    return this._http.post<Utilisateur>(this.apiUrl + '/changerMotDePasse', JSON.stringify(changePassword), { headers: headers });
  }

  updateUtilisateur(utilisateur: Utilisateur): Observable<Utilisateur> {
    return this._http.put<Utilisateur>(this.apiUrl, JSON.stringify(utilisateur), { headers: headers });
  }

  uploadPhoto(formData: FormData, userId: string): Observable<any> {
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });    
    return this._http.post<any>(this.apiUrl + '/upload/' + userId, formData, { headers, responseType: 'text' as 'json' });
  }

  getImageUrl(filename: string): string {
    return `${this.apiUrl}/files/${filename}`;
  }

  changerEtatProfile(utilisateur: Utilisateur): Observable<Utilisateur> {
    return this._http.post<Utilisateur>(this.apiUrl + '/changerEtatProfile', JSON.stringify(utilisateur), { headers: headers });
  }
}
