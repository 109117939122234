export class Client {
    id!: string;
    numeroClient!: string;
    nom!: string;
    prenom!: string;
    telephone!: string;
    email!: string;
    adresse!: string;
    description!: string;
    reseauSocial!: string;
}
