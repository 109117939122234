<div class="modal-content">
  <h2 mat-dialog-title class="modal-header">Rechercher un article</h2>
  <mat-dialog-content>
    <mat-form-field  appearance="outline" class="w-100" style="width: 100%;">
      <input matInput placeholder="Recherche par référence" [formControl]="searchControl">
    </mat-form-field>
    <mat-selection-list class="scrollable-list">
      <mat-list-option *ngFor="let article of filteredArticles | async" (click)="onSelect(article)">
        {{ article.reference }}
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions class="custom-actions">
    <button mat-button mat-dialog-close class="custom-close-button" (click)="onCancel()">Annuler</button>
  </mat-dialog-actions>
</div>