import { Categorie } from "./categorie";
import { SousCategorie } from "./sous-categorie";

export class Modele {
    public id!: string;
    public nomModel!: string;
    public categorie!: Categorie;
    public sousCategorie!: SousCategorie;
    public description!: string;
    public photo!: string;
}
