import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SousCategorie } from '../z-model/sous-categorie';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SousCategorieService {
  apiUrl = environment.baseUrl + 'api/souscategories';

  constructor(private _http: HttpClient) { }

  getSousCategories(): Observable<SousCategorie[]> {
    return this._http.get<SousCategorie[]>(this.apiUrl);
  }
}
