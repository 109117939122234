<div class="modal-content">
    <h2 mat-dialog-title class="modal-header">Article {{ article.reference }}</h2>
    <mat-dialog-content>
        <div class="row image-container">
            <img [src]="getImageUrl(article.modele.photo)" alt="{{ article.modele.description }}"
            class="fixed-size-image"/>
        </div>
        <!-- Liste d'articles sous forme de tableau -->
        <mat-table *ngIf="mouvementsArticle.length != 0" [dataSource]="dataSource" class="mat-elevation-z8 mat-table">
            <ng-container matColumnDef="magasin">
                <mat-header-cell *matHeaderCellDef> Nom du magasin </mat-header-cell>
                <mat-cell *matCellDef="let mouvementArticle">{{ mouvementArticle.statut.libelle }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="dateCre">
                <mat-header-cell *matHeaderCellDef> Date d'affectation </mat-header-cell>
                <mat-cell *matCellDef="let mouvementArticle"> {{ mouvementArticle?.dateCre | date: 'dd/MM/yyyy' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dernierUtilisateur">
                <mat-header-cell *matHeaderCellDef> Utilisateur </mat-header-cell>
                <mat-cell *matCellDef="let mouvementArticle"> {{ mouvementArticle.dernierUtilisateur?.nom }} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator *ngIf="mouvementsArticle.length != 0" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        <p *ngIf="mouvementsArticle.length == 0">Pas de mouvements liés à cet article.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="custom-actions">
        <button mat-button mat-dialog-close class="custom-close-button">Fermer</button>
    </mat-dialog-actions>
</div>