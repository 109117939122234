import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from '../_services/token-storage.service';
import { UtilisateurService } from '../_services/utilisateur.service';
import { Utilisateur } from '../z-model/utilisateur';
import { Role } from '../z-model/role';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent implements OnInit {
  utilisateur!: Utilisateur;
  isLoggedIn = false;
  Role = Role;
  user: any;

  constructor(
    private tokenStorageService: TokenStorageService,
    private _utilisateurService: UtilisateurService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.user = this.tokenStorageService.getUser();
    if (this.isLoggedIn) {      
      this.getDonneesUtilisateur();
    }
  }

  getDonneesUtilisateur(){
    this._utilisateurService.getUtilisateur(this.tokenStorageService.getUser().Utilisateur?.id).subscribe(data => {
      this.utilisateur = data;
    });
  }

  getImageUrl(photo: string): string {
    return this._utilisateurService.getImageUrl(photo);
  }
}
