import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Commande } from '../z-model/commande';
import { LigneCommande } from '../z-model/ligne-commande';

@Injectable({
  providedIn: 'root'
})
export class CommandeLineService {

  apiUrl = environment.baseUrl + 'api/commande-lines';

  constructor(private _http: HttpClient) { }

  addLignesCommande(lignesCommande: LigneCommande[], commande: Commande): Observable<LigneCommande[]> {
    lignesCommande.forEach(element => {
      element.commande.id = commande.id;
    });
    let list: LigneCommande[] = [...lignesCommande];
    return this._http.post<LigneCommande[]>(this.apiUrl + '/bulk', JSON.stringify(list));
  }

  getLignesCommande(idCommande: string): Observable<LigneCommande[]> {
    return this._http.get<LigneCommande[]>(this.apiUrl + '/' + idCommande);
  }
}
