<div class="modal-content">
    <h2 mat-dialog-title class="modal-header">Informations modèle</h2>
    <mat-dialog-content class="modal-content">
        <form [formGroup]="modelFormulaire" novalidate>
            <ng-template matStepLabel>Saisissez les informations sur le modèle</ng-template>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <mat-form-field formGroupName="categorie" appearance="outline" class="w-100">
                        <mat-label>Catégorie</mat-label>
                        <mat-select formControlName="id">
                            <mat-option *ngFor="let categorie of categories" [value]="categorie.id">
                                {{ categorie.libelle }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="modelFormulaire.get('categorie.id')?.hasError('required')">Le libellé de la catégorie est requis</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-3">
                    <mat-form-field formGroupName="sousCategorie" appearance="outline" class="w-100">
                        <mat-label>Sous catégorie</mat-label>
                        <mat-select formControlName="id">
                            <mat-option *ngFor="let sousCategory of sousCategories" [value]="sousCategory.id">
                                {{ sousCategory.libelle }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="modelFormulaire.get('sousCategorie.id')?.hasError('required')">Le libellé de la sous catégorie est requis</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 mb-3">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Nom du modèle</mat-label>
                        <input matInput formControlName="nomModel" required>
                        <mat-error *ngIf="modelFormulaire.get('categorie.id')?.hasError('required')">Le nom du modèle est requis</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 mb-3 grid-container-photo">
                    <div class="w-100 content-photo">
                        <input type="file" (change)="onFileSelected($event)">
                        <mat-error *ngIf="modelFormulaire.get('photo')?.hasError('required')">La photo est requise</mat-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Description</mat-label>
                        <textarea maxlength="255" matInput formControlName="description"></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="modelFormulaire.get('description')?.hasError('maxlength')">La description ne doit pas dépacer 255 caractères</mat-error>
                </div>
            </div>
            <div class="custom-actions">
                <button mat-button (click)="onCancel()">Annuler</button>
                <button mat-button (click)="onSubmit()">Enregistrer</button>
            </div>
        </form>
    </mat-dialog-content>
</div>