import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientService } from '../../_services/client.service';
import { Client } from '../../z-model/client';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrl: './client-form.component.css'
})
export class ClientFormComponent {
  clientForm!: FormGroup;
  client!: Client;

  constructor(
    private fb: FormBuilder,
    private _clientService: ClientService,
    private dialogRef: MatDialogRef<ClientFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Client
  ) {
    this.createForm(data);
  }

  createForm(data: Client) {
    this.clientForm = this.fb.group({
      id: [data.id],
      numeroClient: [data.numeroClient],
      nom: [data.nom, Validators.required],
      prenom: [data.prenom, Validators.required],
      telephone: data.telephone,
      email: [data.email, [Validators.email]],
      adresse: data.adresse,
      reseauSocial: data.reseauSocial,
      description: [data.description]
    });
  }

  onSubmit(): void {
    if (this.clientForm.valid) {
      this._clientService.addClient(this.clientForm.value).subscribe((client) => {
        this.dialogRef.close(client);
      });
    } else {
      this.clientForm.markAllAsTouched();
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
