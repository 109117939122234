import { MouvementsArticleService } from '../../_services/mouvements-article.service';
import { ModeleService } from '../../_services/modele.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Article } from '../../z-model/article';
import { MouvementArticle } from '../../z-model/mouvement-article';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-artcile-mouvement',
  templateUrl: './artcile-mouvement.component.html',
  styleUrl: './artcile-mouvement.component.css'
})
export class ArtcileMouvementComponent implements OnInit {
  mouvementsArticle: MouvementArticle[] = [];
  dataSource = new MatTableDataSource<MouvementArticle>(this.mouvementsArticle);
  displayedColumns: string[] = ['magasin', 'dateCre', 'dernierUtilisateur'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private _mouvementsArticleService: MouvementsArticleService,
    @Inject(MAT_DIALOG_DATA) public article: Article,
    private _modelesService: ModeleService
  ) {}

  ngOnInit(): void {
    this.loadMouvementsArticle();
  }

  loadMouvementsArticle(){
    this._mouvementsArticleService.getMouvementsArticle(this.article.id).subscribe(mouvementsArticle => {
      this.mouvementsArticle = mouvementsArticle;
      this.dataSource.data = mouvementsArticle;
      this.dataSource.paginator = this.paginator;
    })
  }

  getImageUrl(photo: string): string {
    return this._modelesService.getImageUrl(photo);
  }
}
