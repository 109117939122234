import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Modele } from '../z-model/modele';

@Injectable({
  providedIn: 'root'
})
export class ModeleService {

  apiUrl = environment.baseUrl + 'api/modeles';

  constructor(private _http: HttpClient) { }
  
  getModeles(): Observable<Modele[]> {
    return this._http.get<Modele[]>(this.apiUrl);
  }

  addModele(modele: Modele): Observable<Modele> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this._http.post<Modele>(this.apiUrl, JSON.stringify(modele), { headers: headers });
  }

  uploadPhoto(formData: FormData, modelId: string): Observable<any> {
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });    
    return this._http.post<any>(this.apiUrl + '/upload/' + modelId, formData, { headers, responseType: 'text' as 'json' });
  }

  getImageUrl(filename: string): string {
    return `${this.apiUrl}/files/${filename}`;
  }
}
 