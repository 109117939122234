<div class="list-container">
    <p class="app-titre-component">Ajout d'un utilisateur</p>

    <form [formGroup]="utilisateurForm" (ngSubmit)="onSubmit()" style="padding: 20px;" class="mat-elevation-z8 appCommandeForm">
        <div class="row">
            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nom</mat-label>
                    <input matInput formControlName="nom" required>
                    <mat-error *ngIf="utilisateurForm.get('nom')?.hasError('required')">Le nom de l'utilisateur est requis</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Prénom</mat-label>
                    <input matInput formControlName="prenom" required>
                    <mat-error *ngIf="utilisateurForm.get('prenom')?.hasError('required')">Le prénom de l'utilisateur est requis</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Identifiant</mat-label>
                    <input matInput formControlName="username" required>
                    <mat-error *ngIf="utilisateurForm.get('userName')?.hasError('required')">L'identifiant est requis</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Adresse mail</mat-label>
                    <input matInput formControlName="email">
                    <mat-error *ngIf="utilisateurForm.get('mail')?.hasError('required')">L'adresse mail de l'utilisateur est requise</mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Numéro de téléphone</mat-label>
                    <input matInput formControlName="numTel">
                    <mat-error *ngIf="utilisateurForm.get('numTel')?.hasError('required')">Le numéro de téléphone de l'utilisateur est requis</mat-error>
                </mat-form-field>
            </div>

            <div formGroupName="role" class="col-md-4 mb-6">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="id">
                        <mat-option *ngFor="let role of rolesUtilisateurs" [value]="role.id">
                            {{ role.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    
        <div class="d-flex justify-content-between custom-actions">
            <button mat-button color="primary" type="button" (click)="onCancel()">Annuler la saisie</button>
            <button mat-button color="primary" [disabled]="!utilisateurForm.valid" type="submit">Enregistrer</button>
        </div>
    </form>
</div>

