<div class="list-container">
    <p class="app-titre-component">Recherche avancée</p>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" style="padding: 20px;"
        class="mat-elevation-z8 appCommandeForm">
        <div class="row">
            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Client</mat-label>
                    <mat-select formControlName="clientId">
                        <mat-option value="">Tout les clients</mat-option>
                        <mat-option *ngFor="let client of listeClients" [value]="client.id">
                            {{ client.nom + ' ' + client.prenom }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Catégorie</mat-label>
                    <mat-select formControlName="categorie">
                        <mat-option value="">Toutes les catégories</mat-option>
                        <mat-option *ngFor="let categorie of categories" [value]="categorie.id">
                            {{ categorie.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Sous catégorie</mat-label>
                    <mat-select formControlName="sousCategorie">
                        <mat-option value="">Toutes les sous catégories</mat-option>
                        <mat-option *ngFor="let sousCategorie of sousCategories" [value]="sousCategorie.id">
                            {{ sousCategorie.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Mode de paiement</mat-label>
                    <mat-select formControlName="modePaiementId">
                        <mat-option value="">Tout les modes de paiement</mat-option>
                        <mat-option *ngFor="let modePaiement of modesPaiement" [value]="modePaiement.id">
                            {{ modePaiement.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Nom magasin</mat-label>
                    <mat-select formControlName="magasinId">
                        <mat-option value="">Tout les magasins</mat-option>
                        <mat-option *ngFor="let magasin of listeMagasins" [value]="magasin.id">
                            {{ magasin.nom }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Numéro de commande</mat-label>
                    <input matInput formControlName="numeroCommande">
                </mat-form-field>
            </div>
            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Date d'achat du :</mat-label>
                    <input formControlName="minDate" matInput [matDatepicker]="pickerMin">
                    <mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
                    <mat-datepicker #pickerMin></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-3 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Date d'achat au :</mat-label>
                    <input formControlName="maxDate" matInput [matDatepicker]="pickerMax">
                    <mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
                    <mat-datepicker #pickerMax></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-between custom-actions">
            <button mat-button color="primary" type="button" (click)="reinitialiser()">Réinitialiser les champs</button>
            <button mat-button color="primary" type="submit">Envoyer</button>
        </div>
    </form>
    <div *ngIf="noResultsField" style="padding: 20px; margin-top: 20px;" class="mat-elevation-z8 appCommandeForm">
        <div>
            {{ noResults }}
        </div>
    </div>
    <div *ngIf="listCommandeStatistiques?.length !== 0" style="padding: 20px; margin-top: 20px;" class="mat-elevation-z8 appCommandeForm">
        <div class="row justify-content-between">
            <div class="col-md-8 mb-6 text-left">
                <div class="appSumChamp">
                    Total d'article(s) : {{ totalCommandes }}, avec un poids total de {{ totalPoids }} g et un prix total de {{ totalPrix }} DH
                </div>
            </div>
            <div class="col-md-4 mb-6 text-right">
                <button mat-button type="button" class="appInputChamp" (click)="downloadCommandes()">Télécharger les factures</button>
            </div>
        </div>
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table">
            <ng-container matColumnDef="numeroCommande">
                <mat-header-cell *matHeaderCellDef> Numéro de la vente </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.numeroCommande }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateCommande">
                <mat-header-cell *matHeaderCellDef> Date de vente </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.dateCommande | date: 'dd/MM/yyyy' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="nom">
                <mat-header-cell *matHeaderCellDef> Nom du client </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.nom }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="categorie">
                <mat-header-cell *matHeaderCellDef> Catégorie </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.categorie }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="poids">
                <mat-header-cell *matHeaderCellDef> Poids </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.poids }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="caracts">
                <mat-header-cell *matHeaderCellDef> Caracts </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.caracts }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="prixVente">
                <mat-header-cell *matHeaderCellDef> Prix de vente </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.prixVente }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="modePaiement">
                <mat-header-cell *matHeaderCellDef> Mode de paiement </mat-header-cell>
                <mat-cell *matCellDef="let commandeStatistique"> {{ commandeStatistique.modePaiement }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    </div>
</div>