<div class="list-container">
    <p class="app-titre-component">Gestion des stocks</p>

    <div class="container">
        <!-- Filtrage par statut article -->
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="selectedStatut" (selectionChange)="filterStickArticles()">
                <mat-option value="">Tout les sites</mat-option>
                <mat-option *ngFor="let statut of statutsArticle" [value]="statut.libelle">{{ statut.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Champ de recherche par nom modèle -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchNomModel" (input)="filterStickArticles()"
                placeholder="Nom modèle"/>
        </mat-form-field>
        <!-- Filtrage par catégorie -->
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="selectedCategory" (selectionChange)="filterStickArticles()">
                <mat-option value="">Toutes les catégories</mat-option>
                <mat-option *ngFor="let category of categories" [value]="category.libelle">{{ category.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Filtrage par sous catégorie -->
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="selectedSousCategory" (selectionChange)="filterStickArticles()">
                <mat-option value="">Toutes les sous catégories</mat-option>
                <mat-option *ngFor="let sousCategory of sousCategories" [value]="sousCategory.id">{{ sousCategory.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8 mat-table">
        <!-- ID stock Column -->
        <ng-container matColumnDef="libelleStatut">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nom Magasin </mat-header-cell>
            <mat-cell *matCellDef="let stock"> {{stock.libelleStatut}} </mat-cell>
        </ng-container>

        <!-- Magasin Column -->
        <ng-container matColumnDef="model">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nom du Modèle </mat-header-cell>
            <mat-cell *matCellDef="let stock"> {{stock.model.nomModel}} </mat-cell>
        </ng-container>

        <!-- Client Column -->
        <ng-container matColumnDef="total">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Quantité </mat-header-cell>
            <mat-cell *matCellDef="let stock"> {{stock.total}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>