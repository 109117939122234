<div class="modal-content">
    <h2 mat-dialog-title class="modal-header">Téléchargement de la {{ data.fileName }}</h2>
    <mat-dialog-content>
        <p>
            <iframe [src]="data.pdfUrl" width="100%" height="600px"></iframe>
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="custom-actions">
        <button mat-button mat-dialog-close class="custom-close-button">Fermer</button>
    </mat-dialog-actions>
</div>