<div class="list-container">
    <p class="app-titre-component">Gestion des Clients</p>

    <div class="container">
        <!-- Champ de recherche par nom -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchNom" (input)="filterClients()" placeholder="Nom" />
        </mat-form-field>
        <!-- Bouton pour ajouter un nouvel client -->
        <button mat-raised-button class="uniform-size" (click)="openClientForm()">Ajouter un Client</button>
    </div>

    <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8 mat-table">
        <ng-container matColumnDef="numeroClient">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Numéro </mat-header-cell>
            <mat-cell *matCellDef="let client"> {{client.numeroClient}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nom">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nom </mat-header-cell>
            <mat-cell *matCellDef="let client"> {{client.nom}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="prenom">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </mat-header-cell>
            <mat-cell *matCellDef="let client"> {{client.prenom}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="telephone">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Téléphone </mat-header-cell>
            <mat-cell *matCellDef="let client"> {{client.telephone}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
            <mat-cell *matCellDef="let client"> {{client.email}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reseauSocial">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Réseau social </mat-header-cell>
            <mat-cell *matCellDef="let client">
                <a *ngIf="client.reseauSocial" [href]="client.reseauSocial" target="_blank" aria-label="Réseaux sociaux">
                    <mat-icon>share</mat-icon>
                </a>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let client">
                <button mat-icon-button (click)="openClientForm(client)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="supprimerClient(client.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>