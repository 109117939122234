import { HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpContextToken } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenStorageService } from "../_services/token-storage.service";

const TOKEN_HEADER_KEY = 'Authorization';
const SKIP_INTERCEPTOR = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.context.get(SKIP_INTERCEPTOR)) {
      return next.handle(req);
    }

    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      authReq = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` }
      });
    }
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];