<div class="list-container">
    <p class="app-titre-component">Caisses</p>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" style="padding: 20px;"
        class="mat-elevation-z8 appCommandeForm">
        <div class="row">
            <div class="col-md-4 mb-4">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Nom magasin</mat-label>
                    <mat-select formControlName="magasinId">
                        <mat-option value="">Tout les magasins</mat-option>
                        <mat-option *ngFor="let magasin of listeMagasins" [value]="magasin.id">
                            {{ magasin.nom }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4 mb-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Date d'achat du :</mat-label>
                    <input formControlName="minDate" matInput [matDatepicker]="pickerMin">
                    <mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
                    <mat-datepicker #pickerMin></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-4 mb-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Date d'achat au :</mat-label>
                    <input formControlName="maxDate" matInput [matDatepicker]="pickerMax">
                    <mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
                    <mat-datepicker #pickerMax></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mb-4">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Mode de paiement</mat-label>
                    <mat-select formControlName="modePaiementId">
                        <mat-option value="">Tout les modes de paiement</mat-option>
                        <mat-option *ngFor="let modePaiement of modesPaiement" [value]="modePaiement.id">
                            {{ modePaiement.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4 mb-4">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Catégorie</mat-label>
                    <mat-select formControlName="categorie">
                        <mat-option value="">Toutes les catégories</mat-option>
                        <mat-option *ngFor="let categorie of categories" [value]="categorie.id">
                            {{ categorie.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4 mb-4">
                <mat-form-field class="w-100 mr-2" appearance="outline">
                    <mat-label>Sous catégorie</mat-label>
                    <mat-select formControlName="sousCategorie">
                        <mat-option value="">Toutes les sous catégories</mat-option>
                        <mat-option *ngFor="let sousCategorie of sousCategories" [value]="sousCategorie.id">
                            {{ sousCategorie.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex justify-content-between custom-actions">
            <button mat-button color="primary" type="button" (click)="reinitialiser()">Réinitialiser les champs</button>
            <button mat-button color="primary" type="submit">Envoyer</button>
        </div>
    </form>
    <div *ngIf="noResultsField" style="padding: 20px; margin-top: 20px;" class="mat-elevation-z8 appCommandeForm">
        <div>
            {{ noResults }}
        </div>
    </div>
    <div *ngIf="listPaiementStatistiques?.length !== 0" style="padding: 20px; margin-top: 20px;" class="mat-elevation-z8 appCommandeForm">
        <div class="row justify-content-center" style="display: flex; justify-content: space-between; gap: 30px; margin-bottom: 20px;">
        
            <!-- Bloc Total Paiements -->
            <div class="stat-card">
                <p class="stat-title">Entrées en MAD</p>
                <p class="stat-value">{{ totalPayements }} DH</p>
            </div>
        
            <!-- Bloc Total Dépenses -->
            <div class="stat-card">
                <p class="stat-title">Sorties en MAD</p>
                <p class="stat-value">{{ totalDepenses }} DH</p>
            </div>

            <!-- Bloc Somme en Caisse -->
            <div class="stat-card">
                <p class="stat-title">Solde</p>
                <p class="stat-value">{{ totalCaisses }} DH</p>
            </div>
        </div>
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 mat-table">
            <ng-container matColumnDef="dateOperation">
                <mat-header-cell *matHeaderCellDef> Date de l'opération </mat-header-cell>
                <mat-cell *matCellDef="let paiementStatistique"> {{ paiementStatistique.dateOperation | date: 'dd/MM/yyyy' }} </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="totalPayements">
                <mat-header-cell *matHeaderCellDef> Entrées en MAD </mat-header-cell>
                <mat-cell *matCellDef="let paiementStatistique"> {{ paiementStatistique.totalPayements }} </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="totalDepenses">
                <mat-header-cell *matHeaderCellDef> Sorties en MAD </mat-header-cell>
                <mat-cell *matCellDef="let paiementStatistique"> {{ paiementStatistique.totalDepenses }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delta">
                <mat-header-cell *matHeaderCellDef> Solde </mat-header-cell>
                <mat-cell *matCellDef="let paiementStatistique"> {{ paiementStatistique.delta }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    </div>
</div>
