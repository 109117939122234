<div class="list-container">
    <p class="app-titre-component">Gestion des ventes</p>

    <div class="container">
        <!-- Champ de recherche par numéro de la vente -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchNumeroCommande" (input)="filterCommandes()"
                placeholder="Numéro de la vente"/>
        </mat-form-field>
        <!-- Champ de recherche par nom de magasin -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchNomMagasin" (input)="filterCommandes()"
                placeholder="Nom magasin"/>
        </mat-form-field>
        <!-- Champ de recherche par date de la vente -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [matDatepicker]="picker" [(ngModel)]="searchDateCommande" (dateChange)="filterCommandes()" placeholder="Date de la vente" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <!-- Champ de recherche par nom du client -->
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchNomClient" (input)="filterCommandes()"
                placeholder="Nom du client"/>
        </mat-form-field>
        <!-- Filtrage par catégorie -->
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="selectedStatut" (selectionChange)="filterCommandes()">
                <mat-option value="">Tout les sites</mat-option>
                <mat-option *ngFor="let statut of statutsCommande" [value]="statut.libelle">{{ statut.libelle }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- Bouton pour ajouter un nouvel article -->
        <button mat-raised-button class="uniform-size" (click)="addCommande()">Ajouter une vente</button>
    </div>

    <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8 mat-table">
        <!-- ID vente Column -->
        <ng-container matColumnDef="idCommande">
            <mat-header-cell *matHeaderCellDef> N° de vente </mat-header-cell>
            <mat-cell *matCellDef="let commande"> {{commande.numeroCommande}} </mat-cell>
        </ng-container>

        <!-- Magasin Column -->
        <ng-container matColumnDef="magasin">
            <mat-header-cell *matHeaderCellDef> Magasin </mat-header-cell>
            <mat-cell *matCellDef="let commande"> {{commande.magasin.nom}} </mat-cell>
        </ng-container>

        <!-- Client Column -->
        <ng-container matColumnDef="client">
            <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
            <mat-cell *matCellDef="let commande"> {{commande.client?.nom}} </mat-cell>
        </ng-container>

        <!-- Date vente Column -->
        <ng-container matColumnDef="dateCommande">
            <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Tri par date de mise à jour"> Date vente </mat-header-cell>
            <mat-cell *matCellDef="let commande"> {{commande.dateCommande | date: 'dd/MM/yyyy'}} </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Statut </mat-header-cell>
            <mat-cell *matCellDef="let commande"> {{commande.statut.libelle}} </mat-cell>
        </ng-container>

        <!-- Cadeau Column -->
        <ng-container matColumnDef="cadeau">
            <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Tri par cadeau (oui/non)"> Cadeau </mat-header-cell>
            <mat-cell *matCellDef="let commande"> {{commande.cadeau ? 'Oui' : 'Non'}} </mat-cell>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let commande"> {{commande.description}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let commande">
                <button mat-icon-button (click)="addCommande(commande)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="commande.statut.libelle === 'Payée'" mat-icon-button (click)="downloadCommande(commande.numeroCommande)"  matTooltip="Télécharger la facture">
                    <mat-icon>picture_as_pdf</mat-icon>
                </button>
                <button *ngIf="commande.statut.libelle === 'Payée'" mat-icon-button (click)="downloadCertif(commande.numeroCommande)"  matTooltip="Télécharger le certificat">
                    <mat-icon>bookmark</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>

<div class="modal-content">
    <mat-dialog-content>    
        <p *ngIf="pdfUrl">
            <iframe [src]="pdfUrl" width="100%" height="600px"></iframe>
        </p>
    </mat-dialog-content>
</div>