import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Categorie } from '../z-model/categorie';

@Injectable({
  providedIn: 'root'
})
export class CategorieService {

  apiUrl = environment.baseUrl + 'api/categories';

  constructor(private _http: HttpClient) { }

  getCategories(): Observable<Categorie[]> {
    return this._http.get<Categorie[]>(this.apiUrl);
  }

  updateCategorie(categorie: Categorie): Observable<Categorie> {
    return this._http.put<Categorie>(this.apiUrl, categorie);
  }
}
