import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Utilisateur } from './z-model/utilisateur';
import { TokenStorageService } from './_services/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'Oryno Jewellery';
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  isLoggedIn = true;
  utilisateur = new Utilisateur();
  private roles!: string[];
  
  constructor(
    private observer: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    private tokenStorageService: TokenStorageService,
  ) {}


  ngAfterViewInit() {
    this.observer.observe(['(max-width: 850px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
     this.changeDetector.detectChanges();
  }

  ngOnInit(): void {

    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.Utilisateur.roles;
    }
  }

  logout() {
    this.tokenStorageService.signOut();
    this.isLoggedIn = false;
    window.location.href="connexion";
  }
}
