import { Component } from '@angular/core';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UtilisateurService } from '../../_services/utilisateur.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utilisateur } from '../../z-model/utilisateur';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-utilisateur-edit',
  templateUrl: './utilisateur-edit.component.html',
  styleUrl: './utilisateur-edit.component.css'
})
export class UtilisateurEditComponent {
  hidePasswordNouveau: boolean = true;
  hidePasswordAncien: boolean = true;
  selectedFile: File | null = null;
  passwordForm!: FormGroup;
  utilisateur!: Utilisateur;

  constructor(
    private tokenStorageService: TokenStorageService,
    private _utilisateurService: UtilisateurService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
  ) { 
  }
  
  ngOnInit(): void {
    this.getDonneesUtilisateur();
    this.createForm();
  }

  createForm() {
    this.passwordForm = this.fb.group({
      ancienMotDePasse : ['', Validators.required],
      nouveauMotDePasse: ['', Validators.required],
      idUtilisateur: '',
      photo: '',
    });
  }

  getDonneesUtilisateur(){
    this._utilisateurService.getUtilisateur(this.tokenStorageService.getUser().Utilisateur?.id).subscribe(data => {
      this.utilisateur = data;
    });
  }

  togglePasswordVisibilityAncien() {
    this.hidePasswordAncien = !this.hidePasswordAncien;
  }

  togglePasswordVisibilityNouveau() {
    this.hidePasswordNouveau = !this.hidePasswordNouveau;
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const maxSizeInBytes = 1 * 1024 * 1024; // 1 Mo

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      if (file.size > maxSizeInBytes) {
        this.dialog.open(SuccessDialogComponent, {
          data: {
            message: 'Le fichier sélectionné dépasse la taille maximale autorisée de 1 Mo.',
          }
        });
        this.selectedFile = null;
        input.value = '';
      } else {
        this.selectedFile = file;
      }
    } else {
      this.selectedFile = null;
    }
  }

  onSubmit(): void {
    if (this.selectedFile || this.passwordForm.valid) {
      if (this.passwordForm.value.ancienMotDePasse && this.passwordForm.value.nouveauMotDePasse) {
        this.addNewUtilisateur();
      }

      if (this.selectedFile) {
        const formData = new FormData();
        formData.append('photo', this.selectedFile, this.selectedFile.name);
        this._utilisateurService.uploadPhoto(formData, this.utilisateur.id).subscribe(response => {
          const parsedResponse = JSON.parse(response);
          const photoUrl = parsedResponse.path;
          this.utilisateur.photo = photoUrl;
          this._utilisateurService.addUtilisateur(this.utilisateur).subscribe(utilisateur => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Les données ont été bien modifiés',
                onCancel: this.onCancel.bind(this)
              }
            });
          });
        });
      }
    } else {
      this.passwordForm.markAllAsTouched();
    }
  }

  addNewUtilisateur() {
    if (this.passwordForm.valid) {
      this.passwordForm.get('idUtilisateur')?.setValue(this.utilisateur.id);
      this._utilisateurService.modifierMDPUtilisateur(this.passwordForm.value).subscribe({
        next: (utilisateur) => {
          this.dialog.open(SuccessDialogComponent, {
            data: {
              message: 'Les données ont été bien modifiés',
              onCancel: this.onCancel.bind(this)
            }
          });
        },
        error: (err) => {
          this.dialog.open(SuccessDialogComponent, {
            data: {
              message: 'L\'ancien mot de passe est incorrect.',
            }
          });
        }
      });
    }
  }

  onCancel() {
    this.passwordForm.reset();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      window.location.href="commandes";
    });
  }
}
