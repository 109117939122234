import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Article } from '../../z-model/article';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-article-search-dialog',
  templateUrl: './article-search-dialog.component.html',
  styleUrl: './article-search-dialog.component.css'
})
export class ArticleSearchDialogComponent {
  searchControl = new FormControl('');
  filteredArticles: Observable<Article[]>;

  constructor(
    public dialogRef: MatDialogRef<ArticleSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { articles: Article[] }
  ) {
    this.filteredArticles = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string | null): Article[] {
    const filterValue = (value || '').toLowerCase();
    return this.data.articles.filter(article => 
      article.reference.toLowerCase().includes(filterValue)
    );
  }

  onSelect(article: Article): void {
    this.dialogRef.close(article);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
