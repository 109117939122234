import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ModePayement } from '../z-model/mode-payement';

@Injectable({
  providedIn: 'root'
})
export class ModePayementService {

  apiUrl = environment.baseUrl + 'api/mode-payements';

  constructor(private _http: HttpClient) { }

  getModePayement(): Observable<ModePayement[]> {
    return this._http.get<ModePayement[]>(this.apiUrl);
  }
}
