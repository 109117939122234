import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Categorie } from '../z-model/categorie';
import { MagasinStock } from '../z-model/magasin-stock';
import { StatutArticle } from '../z-model/statut-article';
import { CategorieService } from '../_services/categorie.service';
import { MagasinStockService } from '../_services/magasin-stock.service';
import { StatutArticleService } from '../_services/statut-article.service';
import { SousCategorieService } from '../_services/sous-categorie.service';
import { SousCategorie } from '../z-model/sous-categorie';

@Component({
  selector: 'app-stock-magasin',
  templateUrl: './stock-magasin.component.html',
  styleUrl: './stock-magasin.component.css'
})
export class StockMagasinComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  statutsArticle: StatutArticle[] = [];
  @ViewChild(MatSort) sort!: MatSort;
  magasinsStock: MagasinStock[] = [];
  displayedColumns: string[] = ['libelleStatut', 'model', 'total'];
  dataSource = new MatTableDataSource<MagasinStock>(this.magasinsStock);
  selectedSousCategory: string = '';
  selectedCategory: string = '';
  sousCategories: SousCategorie[] = [];
  categories: Categorie[] = [];
  searchNomModel: string = '';
  selectedStatut: string = '';

  constructor(
    private _sousCategoriesService: SousCategorieService,
    private _statutArticleService: StatutArticleService,
    private _magasinStockService: MagasinStockService,
    private _categorieService: CategorieService,
    private _liveAnnouncer: LiveAnnouncer,
  ) { }

  ngOnInit(): void {
    this.loadStatutCommande();
    this.loadMagasinStock();
    this.loadCategories();
    this.loadSousCategories();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item: MagasinStock, property: string) => {
      switch(property) {
        case 'model':
          return item.articles && item.articles.length > 0 ? item.articles[0].modele.nomModel : '';
        default:
          return (item as any)[property];
      }
    };
  }

  loadMagasinStock(): void {
    this._magasinStockService.getMagasinStock().subscribe(magasinStock => {
      this.magasinsStock = magasinStock;
      this.dataSource.data = this.magasinsStock;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  loadStatutCommande() {
    this._statutArticleService.getStatutsArticle().subscribe(data => {
      this.statutsArticle = data;
    });
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  loadSousCategories() {
    this._sousCategoriesService.getSousCategories().subscribe(data => {
      this.sousCategories = data;
    });
  }

  filterStickArticles(): void {
    // this.dataSource.data = this.magasinsStock.filter(stock => {
    //   return (
    //     (!this.searchNomModel || stock.model.nomModel.toLowerCase().includes(this.searchNomModel.toLowerCase())) &&
    //     (!this.selectedCategory || stock.model.categorie.libelle === this.selectedCategory) &&
    //     (!this.selectedSousCategory || stock.model.sousCategorie.id === this.selectedSousCategory) &&
    //     (!this.selectedStatut || stock.libelleStatut === this.selectedStatut)
    //   );
    // });
    // this.dataSource.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
