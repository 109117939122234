<div class="list-container">
    <p class="app-titre-component">Ajout d'une vente</p>

    <form [formGroup]="commandeForm" (ngSubmit)="onSubmit()" style="padding: 20px;"
        class="mat-elevation-z8 appCommandeForm">
        <div formGroupName="commande">
            <div class="row">
                <!-- Magasin -->
                <div class="col-mb-3" [ngClass]="{'col-md-5': !isEditing, 'col-md-6': isEditing}">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Magasin</mat-label>
                        <mat-select formControlName="magasinId" (selectionChange)="onMagasinChange($event.value)">
                            <mat-option *ngFor="let magasin of listeMagasins" [value]="magasin.nom">
                                {{ magasin.nom }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Client et Nouveau Client -->
                <div [ngClass]="{'col-md-7': !isEditing, 'col-md-6': isEditing}">
                    <div class="row">
                        <div class="col-mb-6" [ngClass]="{'col-md-6': !isEditing, 'col-md-12': isEditing}">
                            <mat-form-field class="w-100 mr-2" appearance="outline">
                                <mat-label>Client</mat-label>
                                <mat-select formControlName="clientId">
                                    <mat-option *ngFor="let client of listeClients" [value]="client.id">
                                        {{ client.nom }} {{ client.prenom }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 mb-6">
                            <button *ngIf="!isEditing" mat-button type="button" class="appInputChamp"
                                (click)="addClient()">Créer un nouveau client</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- Date de la vente -->
                <div class="col-mb-3" [ngClass]="{'col-md-3': !isEditing, 'col-md-4': isEditing}">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Date d'achat</mat-label>
                        <input formControlName="dateCommande" matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div [ngClass]="{'col-md-7': !isEditing, 'col-md-8': isEditing}">
                    <div class="row">
                        <div class="col-md-5 col-lg-7">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Description</mat-label>
                                <textarea matInput formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                        <!-- Option Cadeau -->
                        <div class="col-md-3 col-lg-4 d-flex">
                            <mat-checkbox class="w-100" formControlName="cadeau" class="appCheckbox">Cadeau</mat-checkbox>
                            <mat-checkbox class="w-100" formControlName="atelier" class="appCheckbox">Atelier</mat-checkbox>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isEditing" class="col-md-2 mb-6">
                    <button mat-button type="button" class="appInputChamp" (click)="openArticleSearch()" [disabled]="!isMagasinChoosen" [ngClass]="{'disabled-button': !isMagasinChoosen}">Ajouter un article</button>
                </div>
            </div>
        </div>

        <div formArrayName="commandeLines" class="mb-3">
            <div *ngFor="let commandeLine of commandeLines.controls; let i=index" [formGroupName]="i" class="row">
                <!-- Image miniature de l'article -->
                <div class="col-md-1 mb-1">
                    <img class="article-thumbnail"
                        [src]="commandeLine.get('articleId')?.value ? getImageUrl(commandeLine.get('photoArticle')?.value) : 'assets/default-image.jpg'"
                        [alt]="getArticleById(commandeLine.get('articleId')?.value)?.modele?.description || 'Image non disponible'" />
                </div>

                <div *ngIf="!isEditing" class="col-md-2 mb-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Référence</mat-label>
                        <mat-select formControlName="articleId">
                            <mat-option *ngFor="let article of articlesList" [value]="article.id">
                                {{ article.reference }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="isEditing" class="col-mb-3" [ngClass]="{'col-md-2': !isEditing, 'col-md-4': isEditing}">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Article</mat-label>
                        <input matInput formControlName="articleId">
                    </mat-form-field>
                </div>

                <div class="col-md-2 mb-2">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Poids</mat-label>
                        <input matInput type="number" formControlName="poids">
                    </mat-form-field>
                </div>

                <div *ngIf="!isEditing" class="col-md-2 mb-2">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Prix de base</mat-label>
                        <input matInput type="number" formControlName="prixBase">
                    </mat-form-field>
                </div>

                <div *ngIf="!isEditing" class="col-md-2 mb-2">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Réduction</mat-label>
                        <mat-select formControlName="remiseEnPourcentage" (selectionChange)="onRemiseChange($event.value, i)">
                            <mat-option [value]="0">0%</mat-option>
                            <mat-option [value]="5">5%</mat-option>
                            <mat-option [value]="10">10%</mat-option>
                            <mat-option [value]="15">15%</mat-option>
                            <mat-option [value]="20">20%</mat-option>
                            <mat-option [value]="25">25%</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-mb-2" [ngClass]="{'col-md-2': !isEditing, 'col-md-3': isEditing}">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Prix de vente</mat-label>
                        <input matInput type="number" formControlName="prixVente">
                    </mat-form-field>
                </div>

                <div *ngIf="!isEditing" class="col-md-1 mb-2 d-flex align-items-center">
                    <button *ngIf="commandeLines.length > 1" mat-icon-button type="button" (click)="removeArticle(i)"
                        class="appIconChamp">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- Total Global -->
        <div class="row">
            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Total Global</mat-label>
                    <input matInput type="number" [value]="totalGlobal" disabled>
                </mat-form-field>
            </div>
            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Total Payé</mat-label>
                    <input matInput type="number" [value]="totalPaye" disabled>
                </mat-form-field>
            </div>
            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Rest à payer</mat-label>
                    <input matInput type="number" [value]="resteAPayer" disabled>
                </mat-form-field>
            </div>
        </div>

        <!-- Bouton pour ajouter la section des paiements -->
        <div *ngIf="paiements.length === 0" class="row mb-3">
            <div class="col-12">
                <button mat-button color="primary" type="button" (click)="addPaiement()">Ajouter un paiement</button>
            </div>
        </div>

        <!-- Paiements -->
        <div formArrayName="paiements" class="mb-3">
            <div *ngFor="let paiement of paiements.controls; let i=index" [formGroupName]="i" class="row">
                <div formGroupName=modePayement class="col-md-4 mb-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Mode de Paiement</mat-label>
                        <mat-select formControlName="id">
                            <mat-option *ngFor="let mode of modesPaiement" [value]="mode.id">
                                {{ mode.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Montant reglé</mat-label>
                        <input matInput type="number" formControlName="montantRegle">
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Date de paiement</mat-label>
                        <input formControlName="datePayement" matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-2 mb-3 d-flex align-items-center" style="height: 60px;">
                    <button *ngIf="isDeletePaymentAllowed(i)" mat-icon-button type="button" (click)="removePaiement(i)"
                        class="appIconChamp">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button *ngIf="i === (paiements.length - 1) && resteAPayer !== 0" mat-icon-button type="button" (click)="addPaiement()"
                        class="appIconChamp">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button *ngIf="!isDeletePaymentAllowed(i)" mat-icon-button (click)="downloadRecu(i)" matTooltip="Télécharger le reçu de paiement">
                        <mat-icon>picture_as_pdf</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between custom-actions">
            <button mat-button color="primary" type="button" (click)="onCancel()">{{ resteAPayer !== 0 ? 'Annuler la saisie' : 'Retour aux ventes' }}</button>
            <button *ngIf="resteAPayer !== 0" mat-button color="primary" [disabled]="!commandeForm.valid" type="submit">Enregistrer</button>
        </div>
    </form>
</div>