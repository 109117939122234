import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RoleUtilisateur } from '../z-model/role-utilisateur';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  apiUrl = environment.baseUrl + 'api/role';

  constructor(private _http: HttpClient) { }

  getRoles(): Observable<RoleUtilisateur[]> {
    return this._http.get<RoleUtilisateur[]>(this.apiUrl);
  }
}
