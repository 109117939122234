import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleUtilisateur } from '../../z-model/role-utilisateur';
import { RoleService } from '../../_services/role.service';
import { UtilisateurService } from '../../_services/utilisateur.service';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { Utilisateur } from '../../z-model/utilisateur';
import { MagasinService } from '../../_services/magasin.service';
import { Magasin } from '../../z-model/magasin';

@Component({
  selector: 'app-utilisateur-form',
  templateUrl: './utilisateur-form.component.html',
  styleUrl: './utilisateur-form.component.css'
})
export class UtilisateurFormComponent {
  rolesUtilisateurs: RoleUtilisateur[] = [];
  listeMagasins: Magasin[] = [];
  utilisateurForm!: FormGroup;
  utilisateur!: Utilisateur;
  idUtilisateur!: string;
  isEditing!: boolean;

  constructor(
    private _utilisateurService: UtilisateurService,
    private _magasinService: MagasinService,
    private _rolesService: RoleService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
  ) {
    this.createForm();
  }

  createForm() {
    this.utilisateurForm = this.fb.group({
      id : '',
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      username: ['', Validators.required],
      email: '',
      numTel: '',
      magasin : this.fb.group({
        id: ['', Validators.required],
      }),
      estActif: '',
      role : this.fb.group({
        id: ['', Validators.required],
      })
    });
  }

  ngOnInit(): void {
    this.idUtilisateur = this.route.snapshot.paramMap.get('id')!;
    this.isEditing = !(this.idUtilisateur === null || this.idUtilisateur === undefined);
    this.loadRoleUtilisateur();
    this.loadListeMagasins();
    if (this.isEditing) {
      this.getDonneesUtilisateur();
    }
  }

  loadListeMagasins() {
    this._magasinService.getMagasins().subscribe(magasins => {
      this.listeMagasins = magasins;
    });
  }

  loadRoleUtilisateur() {
    this._rolesService.getRoles().subscribe(data => {
      this.rolesUtilisateurs = data;
    });
  }

  getDonneesUtilisateur(){
    this._utilisateurService.getUtilisateur(this.idUtilisateur).subscribe(data => {
      this.utilisateur = data;
      this.patchForm(this.utilisateur);
    });
  }

  patchForm(utilisateur: Utilisateur) {
    this.utilisateurForm.patchValue({
      id : utilisateur.id,
      nom: utilisateur.nom,
      prenom: utilisateur.prenom,
      username: utilisateur.username,
      email: utilisateur.email,
      numTel: utilisateur.numTel,
      magasin: {
        id: utilisateur.magasin?.id,
      },
      estActif: utilisateur.estActif,
      role: {
        id: utilisateur.role.id,
      }
    });
    this.utilisateurForm.get('username')?.disable();
  }

  onSubmit(): void {
    if (this.utilisateurForm.valid) {
      this.utilisateurForm.get('username')?.enable();
      this.addNewUtilisateur();
      this.utilisateurForm.get('username')?.disable();
    }
  }

  addNewUtilisateur() {
    if (this.utilisateurForm.valid) {
      let idControl = this.utilisateurForm.get('id');
      if (idControl && idControl.value === "") {
        this._utilisateurService.addUtilisateur(this.utilisateurForm.value).subscribe({
          next: (utilisateur) => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'L\'utilisateur a été bien ajouté',
                onCancel: this.onCancel.bind(this)
              }
            });
          },
          error: (err) => {
            this.utilisateurForm.get('username')?.enable();
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: this.getErrorMessage(err)
              }
            });
          }
        });
      } else {
        this._utilisateurService.updateUtilisateur(this.utilisateurForm.value).subscribe({
          next: (utilisateur) => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'L\'utilisateur a été bien modifié',
                onCancel: this.onCancel.bind(this)
              }
            });
          },
          error: (err) => {
            this.utilisateurForm.get('username')?.enable();
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: this.getErrorMessage(err)
              }
            });
          }
        })
      };
    }
  }

  getErrorMessage(error: any): string {
    switch (error.status) {
      case 408:
        return 'Cet identifiant existe déjà';
      default:
        return 'Erreur de serveur. Veuillez contacter l\'équipe technique';
    }
  }

  onCancel() {
    this.utilisateurForm.reset();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['utilisateurs']);
    });
  }
}
