import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModificationCategorieComponent } from './modification-categorie/modification-categorie.component';
import { Categorie } from '../z-model/categorie';
import { MatTableDataSource } from '@angular/material/table';
import { CategorieService } from '../_services/categorie.service';

@Component({
  selector: 'app-parametrages',
  templateUrl: './parametrages.component.html',
  styleUrl: './parametrages.component.css'
})
export class ParametragesComponent implements OnInit {
  listCategories!: Categorie[];
  dataSource = new MatTableDataSource<Categorie>(this.listCategories);
  displayedColumns: string[] = ['libelle', 'prixParGramme', 'actions'];

  constructor(
    private _categorieService: CategorieService,
    public dialog: MatDialog,
  ){}

  ngOnInit(): void {
    this.loadCategories();
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(categories => {
      this.listCategories = categories.filter(category => category.libelle !== 'Diamant');;
      this.dataSource.data = categories.filter(category => category.libelle !== 'Diamant');;
    });
  }

  editPrixOr(categorie: Categorie){
    this.dialog.open(ModificationCategorieComponent, {
      width: '600px',
      data: categorie
    });
  }
}
