<div class="list-container">
  <p class="app-titre-component">Gestion des modèles</p>

  <div class="container">
    <!-- Champ de recherche par nom du modèle -->
    <mat-form-field appearance="outline" class="uniform-size">
      <input matInput [(ngModel)]="searchNomModele" (input)="filterModeles()" placeholder="Nom du modèle" />
    </mat-form-field>
    <!-- Champ de recherche par description -->
    <mat-form-field appearance="outline" class="uniform-size">
      <input matInput [(ngModel)]="searchQuery" (input)="filterModeles()" placeholder="Description" />
    </mat-form-field>
    <!-- Filtrage par catégorie -->
    <mat-form-field appearance="outline" class="uniform-size">
      <mat-select [(ngModel)]="selectedCategory" (selectionChange)="filterModeles()">
        <mat-option value="">Toutes les catégories</mat-option>
        <mat-option *ngFor="let category of categories" [value]="category.id">{{ category.libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Filtrage par sous catégorie -->
    <mat-form-field appearance="outline" class="uniform-size">
      <mat-select [(ngModel)]="selectedSousCategory" (selectionChange)="filterModeles()">
        <mat-option value="">Toutes les sous catégories</mat-option>
        <mat-option *ngFor="let sousCategory of sousCategories" [value]="sousCategory.id">{{ sousCategory.libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Bouton pour ajouter un nouvel article -->
    <button mat-raised-button class="uniform-size" (click)="openModeleForm(null)" *ngIf="user.Utilisateur?.role.name === Role.Admin">Ajouter un modèle</button>
  </div>
  <!-- Liste d'articles sous forme de cards -->

  <div class="article-grid">
    <mat-card *ngFor="let modele of paginatedCards" class="article-card" appearance="outlined">
      <mat-card-header>
        <mat-card-title class="single-line-title">{{ modele.nomModel }}</mat-card-title>
        <mat-card-subtitle>{{ modele.categorie.libelle }}</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [src]="getImageUrl(modele.photo)" alt="{{ modele.description }}">
      <mat-card-content class="scrollable-content">
        <p>{{ modele.description }}</p>
      </mat-card-content>
      <mat-card-actions class="custom-actions">
        <button mat-button (click)="openModeleDialog(modele)">Détails</button>
        <button mat-button (click)="openModeleForm(modele)" *ngIf="user.Utilisateur?.role.name === Role.Admin">Modifier</button>
        <!-- <button mat-icon-button><mat-icon>delete</mat-icon></button> -->
      </mat-card-actions>
    </mat-card>
  </div>
  <mat-paginator [length]="totalCards" [pageSize]="pageSize" [pageSizeOptions]="[6, 12, 24]"
    (page)="onPageChange($event)">
  </mat-paginator>
</div>