<div class="list-container" *ngIf="utilisateur">
    <p class="app-titre-component">Mes informations</p>
    <div *ngIf="utilisateur" style="padding: 20px;" class="mat-elevation-z8 appCommandeForm">
        <div class="row">
            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nom</mat-label>
                    <input matInput [(ngModel)]="utilisateur.nom" [disabled]="true">
                </mat-form-field>
            </div>

            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Prénom</mat-label>
                    <input matInput [(ngModel)]="utilisateur.prenom" [disabled]="true">
                </mat-form-field>
            </div>

            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Identifiant</mat-label>
                    <input matInput [(ngModel)]="utilisateur.username" [disabled]="true">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Adresse mail</mat-label>
                    <input matInput [(ngModel)]="utilisateur.email" [disabled]="true">
                </mat-form-field>
            </div>

            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Numéro de téléphone</mat-label>
                    <input matInput [(ngModel)]="utilisateur.numTel" [disabled]="true">
                </mat-form-field>
            </div>

            <div class="col-md-4 mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Role</mat-label>
                    <input matInput [(ngModel)]="utilisateur.role.name" [disabled]="true">
                </mat-form-field>
            </div>
        </div>
        <form *ngIf="utilisateur" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-6 mb-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Ancien mot de passe</mat-label>
                        <input matInput [type]="hidePasswordAncien ? 'password' : 'text'" formControlName="ancienMotDePasse">
                        <button xs mat-icon-button matSuffix (click)="togglePasswordVisibilityAncien()" type="button">
                            <mat-icon>{{hidePasswordAncien ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
    
                <div class="col-md-6 mb-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Nouveau mot de passe</mat-label>
                        <input matInput [type]="hidePasswordNouveau ? 'password' : 'text'" formControlName="nouveauMotDePasse">
                        <button sm mat-icon-button matSuffix (click)="togglePasswordVisibilityNouveau()" type="button">
                            <mat-icon>{{hidePasswordNouveau ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-3 align-items-end grid-container-photo">
                  <div class="w-100 content-photo">
                    <label mat-raised-button color="primary" for="fileInput">Choisir un fichier</label>
                    <input type="file" id="fileInput" (change)="onFileSelected($event)" hidden>
                    <span class="file-name">{{ selectedFile?.name || 'Changer ma photo de profil' }}</span>
                  </div>
                </div>
              </div>
            <div class="d-flex justify-content-between custom-actions">
                <button mat-button color="primary" type="button" (click)="onCancel()">Annuler la saisie</button>
                <button mat-button color="primary" type="submit">Enregistrer</button>
            </div>
        </form>
    </div>
</div>
