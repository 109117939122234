<div class="list-container">
    <p class="app-titre-component">Gestion de paramétrage</p>
    <div style="padding: 20px;" class="mat-elevation-z8 appCommandeForm">
        <h2 class="app-titre-component">Liste des catégories</h2>
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat-table">
            <ng-container matColumnDef="libelle">
                <mat-header-cell *matHeaderCellDef> Libelle de la catégorie </mat-header-cell>
                <mat-cell *matCellDef="let categorie">{{ categorie.libelle }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="prixParGramme">
                <mat-header-cell *matHeaderCellDef> Prix par gramme </mat-header-cell>
                <mat-cell *matCellDef="let categorie"> {{ categorie?.prixParGramme }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let categorie">
                    <button mat-icon-button (click)="editPrixOr(categorie)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>