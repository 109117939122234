import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modele } from '../../z-model/modele';
import { ModeleService } from '../../_services/modele.service';

@Component({
  selector: 'app-modele-details',
  templateUrl: './modele-details.component.html',
  styleUrl: './modele-details.component.css'
})
export class ModeleDetailsComponent {
  constructor(
    private _modelesService: ModeleService,
    @Inject(MAT_DIALOG_DATA) public modele: Modele
  ) {}

  getImageUrl(photo: string): string {
    return this._modelesService.getImageUrl(photo);
  }
}
