import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Client } from '../../z-model/client';
import { ClientService } from '../../_services/client.service';
import { ClientFormComponent } from '../client-form/client-form.component';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrl: './client-list.component.css'
})
export class ClientListComponent  implements OnInit, AfterViewInit {

  clients: Client[] = [];
  filteredClients: Client[] = [];
  displayedColumns: string[] = ['numeroClient', 'nom', 'prenom', 'telephone', 'email', 'reseauSocial', 'actions'];
  dataSource = new MatTableDataSource<Client>(this.filteredClients);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  searchNom: string = '';

  constructor(
    private _clientService: ClientService, 
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog
    ) {}

  ngOnInit(): void {
    this.loadClients();
  }
  
  loadClients(): void {
    this._clientService.getClients().subscribe(clients => {
      this.clients = clients;
      this.filteredClients = clients;
      this.dataSource.data = this.filteredClients;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filterClients(): void {
    this.dataSource.data = this.clients.filter(client => {
      return (
        (!this.searchNom || client.nom.toLowerCase().includes(this.searchNom.toLowerCase()))
      );
    });
    this.dataSource.paginator = this.paginator;
  }

  openClientForm(client?: Client): void {
    const dialogRef = this.dialog.open(ClientFormComponent, {
      width: '400px',
      data: client ? client : new Client()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  supprimerClient(idClient: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Êtes-vous sûr de vouloir supprimer ce client ? Cette action est irréversible.'
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._clientService.deleteClient(idClient).subscribe({
          next: () => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Client supprimé avec succès.'
              }
            });
            this.loadClients();
          },
          error: (err) => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Une erreur s\'est produite lors de la suppression du client. Le client n\'a pas été supprimé.'
              }
            });
          }
        });
      }
    });
  }
}
