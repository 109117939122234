import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../z-model/role';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private tokenStorage: TokenStorageService,
    private _router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const requiredRoles = next.data['roles'] as Role[];
    const token = this.tokenStorage.getToken();

    if (!this.tokenStorage.isLoggedIn() || !token) {
      this._router.navigate(['connexion']);
      return false;
    }

    const userRole = this.tokenStorage.getUser().Utilisateur.role.name;

    if (!requiredRoles || !requiredRoles.includes(userRole)) {
      this._router.navigate(['unauthorized']);
      return false;
    }

    return true;
  }
}
