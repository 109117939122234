import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StatutArticleService } from '../../_services/statut-article.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArticleService } from '../../_services/article.service';
import { ModeleService } from '../../_services/modele.service';
import { StatutArticle } from '../../z-model/statut-article';
import { Component, Inject, OnInit } from '@angular/core';
import { Article } from '../../z-model/article';
import { Modele } from '../../z-model/modele';

@Component({
  selector: 'app-article-form',
  templateUrl: './article-form.component.html',
  styleUrl: './article-form.component.css'
})
export class ArticleFormComponent implements OnInit {
  statutsArticle: StatutArticle[] = [];
  isPrixRequired: boolean = false;
  articleForm!: FormGroup;
  modeles: Modele[] = [];
  article!: Article;

  constructor(
    public dialogRef: MatDialogRef<ArticleFormComponent>,
    private _statutArticleService: StatutArticleService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _articleService: ArticleService,
    private _modelesService: ModeleService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) {
    this.article = data.article;
    this.createFormArticle();
    this.patchFormArticle(this.article);
  }

  ngOnInit(): void {
    this.loadModeles();
    this.loadListeStatutsArticle();
    this.setupStatusChangeListener();
    this.setupModeleCategoryListener();
  }

  loadModeles(): void {
    this._modelesService.getModeles().subscribe(modeles => {
      this.modeles = modeles;
    });
  }

  loadListeStatutsArticle() {
    this._statutArticleService.getStatutsArticleSansVendu().subscribe(data => {
      this.statutsArticle = data;
    });
  }

  createFormArticle() {
    this.articleForm = this._formBuilder.group({
      id: '',
      modele: this._formBuilder.group({
        id: ['', Validators.required]
      }),
      statut: this._formBuilder.group({
        id: ['', Validators.required],
        libelle: ''
      }),
      poids: ['', Validators.required],
      caracts: '',
      prix: '',
    });
  }

  setupStatusChangeListener() {
    this.articleForm.get('statut.id')?.valueChanges.subscribe((statusId) => {
      const selectedStatus = this.statutsArticle.find(statut => statut.id === statusId);
      this.articleForm.get('statut.libelle')?.setValue(selectedStatus ? selectedStatus.libelle : '');
    });
  }

  patchFormArticle(article: Article) {
    this.articleForm.patchValue({
      id: article.id,
      modele: {
        id: article.modele ? article.modele.id : null
      },
      statut: {
        id: article.statut ? article.statut.id : null,
        libelle: article.statut ? article.statut.libelle : null
      },
      poids: article.poids,
      caracts: article.caracts,
      prix: article.prix,
    });
  }

  onSubmit() {
    if (this.articleForm.valid) {
      if (!this.article.id) {
        this._articleService.addArticle(this.articleForm.value).subscribe({
          next: () => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Article ajouté avec succès.'
              }
            });
          },
          error: (err) => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Une erreur s\'est produite lors de l\'ajout de l\'article. L\'article n\'a pas été ajouté.'
              }
            });
          }
        });
      } else {
        this._articleService.updateArticle(this.articleForm.value).subscribe({
          next: () => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Article modifié avec succès.'
              }
            });
          },
          error: (err) => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Une erreur s\'est produite lors de la modification de l\'article. L\'article n\'a pas été modifié.'
              }
            });
          }
        });
      }
      this.dialogRef.close(this.article);
    } else {
      this.articleForm.markAllAsTouched();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  setupModeleCategoryListener() {
    this.articleForm.get('modele.id')?.valueChanges.subscribe((modeleId) => {
      const selectedModele = this.modeles.find(modele => modele.id === modeleId);
      this.isPrixRequired = selectedModele?.categorie.libelle === 'Diamant';
      if (this.isPrixRequired) {
        this.articleForm.get('prix')?.setValidators([Validators.required]);
      } else {
        this.articleForm.get('prix')?.clearValidators();
        this.articleForm.get('prix')?.setValue('');
      }
      this.articleForm.get('prix')?.updateValueAndValidity();
    });
  }

  getImageUrl(photo: string): string {
    return this._modelesService.getImageUrl(photo);
  }
}
