<div class="modal-content">
    <h2 mat-dialog-title class="modal-header">{{ modele.nomModel }}</h2>
    <mat-dialog-content>
        <img [src]="getImageUrl(modele.photo)" alt="{{ modele.description }}"
            style="width: 100%; height: auto; margin-bottom: 15px;" />
        <p><strong>Modèle:</strong> {{ modele.nomModel }}</p>
        <p><strong>Catégorie:</strong> {{ modele.categorie.libelle }}</p>
        <p><strong>Sous catégorie:</strong> {{ modele.sousCategorie.libelle }}</p>
        <p><strong>Description:</strong> {{ modele.description }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="custom-actions">
        <button mat-button mat-dialog-close class="custom-close-button">Fermer</button>
    </mat-dialog-actions>
</div>