import { Modele } from "./modele";
import { StatutArticle } from "./statut-article";

export class Article {
    public id!: string;
    public reference!: string;
    public refCertifAuth!: string;
    public modele!: Modele;
    public statut!: StatutArticle;
    public poids!: number;
    public caracts!: string;
    public prix!: number;
}
