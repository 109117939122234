import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleFormComponent } from './articles/article-form/article-form.component';
import { ArticleListComponent } from './articles/article-list/article-list.component';
import { ClientFormComponent } from './client/client-form/client-form.component';
import { ClientListComponent } from './client/client-list/client-list.component';
import { CommandeFormComponent } from './commande/commande-form/commande-form.component';
import { CommandeListComponent } from './commande/commande-list/commande-list.component';
import { ConnexionComponent } from './connexion/connexion.component';
import { ModeleDetailsComponent } from './modele/modele-details/modele-details.component';
import { ModeleFormComponent } from './modele/modele-form/modele-form.component';
import { ModeleListComponent } from './modele/modele-list/modele-list.component';
import { StockMagasinComponent } from './stock-magasin/stock-magasin.component';
import { Role } from './z-model/role';
import { AuthGuardService } from './_services/auth-guard.service';
import { UtilisateurListComponent } from './utilisateur/utilisateur-list/utilisateur-list.component';
import { UtilisateurFormComponent } from './utilisateur/utilisateur-form/utilisateur-form.component';
import { UtilisateurEditComponent } from './utilisateur/utilisateur-edit/utilisateur-edit.component';
import { ParametragesComponent } from './parametrages/parametrages.component';
import { StatistiquesComponent } from './statistiques/statistiques.component';
import { StatistiquesPayementsComponent } from './statistiques-payements/statistiques-payements.component';

const routes: Routes = [
  { path: 'connexion', component: ConnexionComponent },
  { path: 'articles', component: ArticleListComponent },
  { path: 'articles/new', component: ArticleFormComponent, canActivate: [AuthGuardService], data: { roles: [Role.Admin, Role.Gestionnaire] }},
  { path: 'clients', component: ClientListComponent },
  { path: 'clients/new', component: ClientFormComponent},
  { path: 'commandes', component: CommandeListComponent },
  { path: 'commandes/new', component: CommandeFormComponent },
  { path: 'commandes/:id', component: CommandeFormComponent },
  { path: 'modeles', component: ModeleListComponent},
  { path: 'modeles/new', component: ModeleFormComponent, canActivate: [AuthGuardService], data: { role: [Role.Admin, Role.Gestionnaire] }},
  { path: 'modeles/:id', component: ModeleDetailsComponent},
  { path: 'stock', component: StockMagasinComponent, canActivate: [AuthGuardService], data: { roles: [Role.Admin, Role.Gestionnaire] }},
  { path: 'utilisateurs', component: UtilisateurListComponent, canActivate: [AuthGuardService], data: { roles: [Role.Admin] }},
  { path: 'utilisateur/new', component: UtilisateurFormComponent, canActivate: [AuthGuardService], data: { roles: [Role.Admin] }},
  { path: 'utilisateur/:id', component: UtilisateurFormComponent, canActivate: [AuthGuardService], data: { roles: [Role.Admin] }},
  { path: 'profile', component: UtilisateurEditComponent },
  { path: 'statistiques', component: StatistiquesComponent },
  { path: 'parametrages', component: ParametragesComponent },
  { path: 'statistiquesPayements', component: StatistiquesPayementsComponent },
  { path: '', redirectTo: '/commandes', pathMatch: 'full' },
  { path: '**', redirectTo: '/commandes' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
