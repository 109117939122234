<div class="modal-content">
    <h2 mat-dialog-title class="modal-header">Catégorie {{ categorie.libelle }}</h2>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-12 mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nouveau prix par gramme</mat-label>
                    <input matInput [(ngModel)]="nouveauPrix" type="number">
                </mat-form-field>
            </div>
        </div>
        <div class="custom-actions">
            <button mat-button (click)="onCancel()">Annuler</button>
            <button mat-button (click)="updatePrice()">Enregistrer</button>
        </div>
    </mat-dialog-content>
</div>