import { Component, ViewChild } from '@angular/core';
import { SousCategorieService } from '../_services/sous-categorie.service';
import { ModePayementService } from '../_services/mode-payement.service';
import { CommandeStatistiques } from '../z-model/commande-statistiques';
import { StatistiquesService } from '../_services/statistiques.service';
import { CategorieService } from '../_services/categorie.service';
import { MagasinService } from '../_services/magasin.service';
import { MatTableDataSource } from '@angular/material/table';
import { ClientService } from '../_services/client.service';
import { MatPaginator } from '@angular/material/paginator';
import { SousCategorie } from '../z-model/sous-categorie';
import { ModePayement } from '../z-model/mode-payement';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Categorie } from '../z-model/categorie';
import { Magasin } from '../z-model/magasin';
import { Client } from '../z-model/client';
import { CommandeService } from '../_services/commande.service';

@Component({
  selector: 'app-statistiques',
  templateUrl: './statistiques.component.html',
  styleUrl: './statistiques.component.css'
})
export class StatistiquesComponent {
  displayedColumns: string[] = ['nom', 'dateCommande', 'prixVente', 'numeroCommande', 'caracts', 'poids', 'categorie', 'modePaiement'];
  listCommandeStatistiques: CommandeStatistiques[] = [];
  dataSource = new MatTableDataSource<CommandeStatistiques>(this.listCommandeStatistiques);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  sousCategories: SousCategorie[] = [];
  modesPaiement: ModePayement[] = [];
  noResultsField: boolean = false;
  listeMagasins: Magasin[] = [];
  categories: Categorie[] = [];
  listeClients: Client[] = [];
  totalCommandes: number = 0;
  searchForm!: FormGroup;
  totalPoids: number = 0;
  totalPrix: number = 0;
  noResults!: string;

  constructor(
    private _sousCategoriesService: SousCategorieService,
    private _modePayementService: ModePayementService,
    private _statistiquesService: StatistiquesService,
    private _categorieService: CategorieService,
    private _commandeService: CommandeService,
    private _magasinService: MagasinService,
    private _clientService: ClientService,
    private fb: FormBuilder,
  ) {
    this.loadListeModePaiement();
    this.loadSousCategories();
    this.loadListeMagasins();
    this.loadListeClients();
    this.creatSearchForm();
    this.loadCategories();
  }

  creatSearchForm() {
    this.searchForm = this.fb.group({
      minDate: '',
      maxDate: '',
      clientId: '',
      numeroCommande: '',
      categorie: '',
      sousCategorie: '',
      modePaiementId: '',
      magasinId: '',
    });
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  loadSousCategories() {
    this._sousCategoriesService.getSousCategories().subscribe(data => {
      this.sousCategories = data;
    });
  }

  loadListeModePaiement() {
    this._modePayementService.getModePayement().subscribe(modes => {
      this.modesPaiement = modes;
    });
  }

  loadListeMagasins() {
    this._magasinService.getMagasins().subscribe(magasins => {
      this.listeMagasins = magasins;
    });
  }

  loadListeClients() {
    this._clientService.getClients().subscribe(clients => {
      this.listeClients = clients;
    });
  }

  onSubmit(){
    this.noResultsField = false;
    this.noResults = "";
    this._statistiquesService.getCommandeStatistiques(this.searchForm.value).subscribe(commandesStatistiques => {
      this.listCommandeStatistiques = commandesStatistiques;
      this.dataSource.data = commandesStatistiques;
      this.dataSource.paginator = this.paginator;
      this.totalCommandes = commandesStatistiques.length;
      this.totalPrix = commandesStatistiques.reduce((acc, commandeStatistique) => acc + commandeStatistique.prixVente, 0);
      this.totalPoids = commandesStatistiques.reduce((acc, commandeStatistique) => acc + commandeStatistique.poids, 0);
      if (commandesStatistiques.length === 0) {
        this.noResultsField = true;
        this.noResults = "Aucun résultat ne correspond à votre recherche.";
      }
    });
  }  

  reinitialiser(){
    this.searchForm.reset();
    this.listCommandeStatistiques = [];
    this.noResultsField = false;
    this.noResults = "";
  }

  downloadCommandes() {
    console.log(this.listCommandeStatistiques.map(commande => commande.numeroCommande));
    this._commandeService.getFacturesCommandes(this.listCommandeStatistiques.map(commande => commande.numeroCommande)).subscribe((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'factures.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error("Erreur lors de la récupération du fichier zip", error);
    });
  }
}
