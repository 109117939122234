import { RoleUtilisateur } from "./role-utilisateur";

export class Utilisateur {
    id!: string;
    nom!: string;
    prenom!: string;
    email!: string;
    photo!: string;
    numTel!: string;
    username!: string;
    motDePasse!: string;
    role!: RoleUtilisateur;
    estActif!: string;
}
