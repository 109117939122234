import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommandeStatistiques } from '../z-model/commande-statistiques';
import { PaiementStatistiques } from '../z-model/paiement-statistiques';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class StatistiquesService {

  apiUrl = environment.baseUrl + 'api/statistiques';

  constructor(private _http: HttpClient) { }

  getCommandeStatistiques(statistiquesCommande: any): Observable<CommandeStatistiques[]> {
    return this._http.post<CommandeStatistiques[]>(this.apiUrl + '/details', JSON.stringify(statistiquesCommande), { headers: headers });
  }

  getPaiementStatistiques(statistiquePayementFilter: any): Observable<PaiementStatistiques[]> {
    return this._http.post<PaiementStatistiques[]>(this.apiUrl + '/detailsPayements', JSON.stringify(statistiquePayementFilter), { headers: headers });
  }
}
