import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Article } from '../../z-model/article';
import { Categorie } from '../../z-model/categorie';
import { StatutArticle } from '../../z-model/statut-article';
import { ArticleService } from '../../_services/article.service';
import { CategorieService } from '../../_services/categorie.service';
import { StatutArticleService } from '../../_services/statut-article.service';
import { ArticleFormComponent } from '../article-form/article-form.component';
import { SousCategorieService } from '../../_services/sous-categorie.service';
import { SousCategorie } from '../../z-model/sous-categorie';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { ArtcileMouvementComponent } from '../artcile-mouvement/artcile-mouvement.component';
import { TokenStorageService } from '../../_services/token-storage.service';
import { Role } from '../../z-model/role';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrl: './article-list.component.css'
})
export class ArticleListComponent implements OnInit, AfterViewInit {
  articles: Article[] = [];
  statutsArticle: StatutArticle[] = [];
  displayedColumns!: string[];
  dataSource = new MatTableDataSource<Article>(this.articles);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  selectedCategory: string = '';
  selectedSousCategory: string = '';
  categories: Categorie[] = [];
  sousCategories: SousCategorie[] = [];
  selectedEtat: string = '';
  searchRefQuery: string = '';
  searchQuery: string = '';
  totalArticles: number = 0;
  totalPoids: number = 0;
  totalPrix: number = 0;
  Role = Role;
  user: any;

  constructor(
    private _statutArticleService: StatutArticleService,
    private _sousCategoriesService: SousCategorieService,
    private tokenStorageService: TokenStorageService,
    private _categorieService: CategorieService,
    private _articleService: ArticleService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.loadArticles();
    this.loadCategories();
    this.loadStatutsArticle();
    this.loadSousCategories();
    this.user = this.tokenStorageService.getUser();
    this.displayedColumns = this.user.Utilisateur?.role.name === Role.Admin ? ['ref', 'nomModel', 'etat', 'poids', 'prix', 'carats', 'actions'] : ['ref', 'nomModel', 'etat', 'poids', 'prix', 'carats'];
  }

  loadArticles(): void {
    this._articleService.getArticles().subscribe(articles => {
      this.articles = articles;
      this.dataSource.data = articles;
      this.dataSource.paginator = this.paginator;
      this.totalArticles = articles.length;
      this.totalPoids = Number(articles.reduce((acc, article) => acc + article.poids, 0).toFixed(2));
      this.totalPrix = Number(articles.reduce((acc, article) => acc + article.prix, 0).toFixed(2));
    });
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  loadSousCategories() {
    this._sousCategoriesService.getSousCategories().subscribe(data => {
      this.sousCategories = data;
    });
  }

  loadStatutsArticle(): void {
    this._statutArticleService.getStatutsArticle().subscribe(data => {
      this.statutsArticle = data;
    });
  }

  filterArticles(): void {
    this.dataSource.data = this.articles.filter(article => {
      return (
        (!this.selectedEtat || article.statut.id === this.selectedEtat) &&
        (!this.selectedCategory || article.modele.categorie.id === this.selectedCategory) &&
        (!this.selectedSousCategory || article.modele.sousCategorie.id === this.selectedSousCategory) &&
        (!this.searchQuery || article.modele.nomModel.toLowerCase().includes(this.searchQuery.toLowerCase())) &&
        (!this.searchRefQuery || article.reference.toLowerCase().includes(this.searchRefQuery.toLowerCase()))
      );
    });
    this.totalArticles = this.dataSource.data.length;
    this.totalPoids = Number(this.dataSource.data.reduce((acc, article) => acc + article.poids, 0).toFixed(2));
    this.totalPrix = Number(this.dataSource.data.reduce((acc, article) => acc + article.prix, 0).toFixed(2));
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  openArticleForm(article: Article | null) {
    const dialogRef = this.dialog.open(ArticleFormComponent, {
      width: '600px',
      data: { article: article ? article : new Article() }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadArticles();
        this.filterArticles();
        this.ngOnInit();
      }
    });
  }

  supprimerArticle(idArticle: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: 'Êtes-vous sûr de vouloir supprimer cet article ? Cette action est irréversible.',
        confirmText: 'Supprimer',
        cancelText: 'Annuler'
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this._articleService.deleteArticle(idArticle).subscribe({
          next: () => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Article supprimé avec succès.',
              },
            });
            this.loadArticles();
          },
          error: (err) => {
            this.dialog.open(SuccessDialogComponent, {
              data: {
                message: 'Une erreur s\'est produite lors de la suppression de l\'article. L\'article n\'a pas été supprimé.',
              },
            });
          },
        });
      }
    });
  }

  openArticleMvtsDialog(artcile: Article) {
    this.dialog.open(ArtcileMouvementComponent, {
      width: '600px',
      data: artcile
    });
  }
}
