import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StatutArticle } from '../z-model/statut-article';

@Injectable({
  providedIn: 'root'
})
export class StatutArticleService {

  apiUrl = environment.baseUrl + 'api/statut-articles';

  constructor(private _http: HttpClient) { }

  getStatutsArticle(): Observable<StatutArticle[]> {
    return this._http.get<StatutArticle[]>(this.apiUrl);
  }

  getStatutsArticleSansVendu(): Observable<StatutArticle[]> {
    return this._http.get<StatutArticle[]>(this.apiUrl + '/SansVendu');
  }
}
