import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Commande } from '../../z-model/commande';
import { StatutCommande } from '../../z-model/statut-commande';
import { CommandeService } from '../../_services/commande.service';
import { StatutCommandeService } from '../../_services/statut-commande.service';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '../../pdf-viewer-dialog/pdf-viewer-dialog.component';

@Component({
  selector: 'app-commande-list',
  templateUrl: './commande-list.component.html',
  styleUrl: './commande-list.component.css'
})

export class CommandeListComponent implements OnInit, AfterViewInit {

  commandes: Commande[] = [];
  statutsCommande: StatutCommande[] = [];
  displayedColumns: string[] = ['idCommande', 'magasin', 'client', 'dateCommande', 'status', 'cadeau', 'actions'];
  dataSource = new MatTableDataSource<Commande>(this.commandes);
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pdfUrl: SafeResourceUrl | undefined;
  @ViewChild(MatSort) sort!: MatSort;
  searchNumeroCommande: string = '';
  searchNomMagasin: string = '';
  searchNomClient: string = '';
  selectedStatut: string = '';
  searchDateCommande!: Date;

  constructor(
    private _statutCommandeService: StatutCommandeService,
    private _commandeService: CommandeService,
    private _liveAnnouncer: LiveAnnouncer,
    private sanitizer: DomSanitizer,
    private pdfDialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadStatutCommande();
    this.loadCommandes();
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state?.refresh) {
      this.refreshCommandes();
    }
  }
  
  refreshCommandes() {
    this._commandeService.getCommandes().subscribe(commandes => {
      this.commandes = commandes;
      this.dataSource.data = commandes;
      this.dataSource.paginator = this.paginator;
    });
  }

  loadCommandes() {
    this._commandeService.getCommandes().subscribe(commandes => {
      this.commandes = commandes;
      this.dataSource.data = commandes;
      this.dataSource.paginator = this.paginator;
    });
  }
  
  loadStatutCommande() {
    this._statutCommandeService.getStatutCommande().subscribe(data => {
      this.statutsCommande = data;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.commandes;
    this.dataSource.sort = this.sort;
  }

  filterCommandes(): void {
    this.dataSource.data = this.commandes.filter(commande => {
      return (
        (!this.selectedStatut || commande.statut.libelle === this.selectedStatut) &&
        (!this.searchNumeroCommande || commande.numeroCommande.includes(this.searchNumeroCommande)) &&
        (!this.searchNomClient || commande.client.nom.toLowerCase().includes(this.searchNomClient.toLowerCase())) &&
        (!this.searchNomMagasin || commande.magasin.nom.toLowerCase().includes(this.searchNomMagasin.toLowerCase())) &&
        (!this.searchDateCommande || new Date(commande.dateCommande).toDateString() === this.searchDateCommande.toDateString())
      );
    });
    this.dataSource.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  addCommande(commande?: Commande): void {
    if (commande) {
      window.location.href = `/commandes/${commande.id}`;
    } else {
      window.location.href = `/commandes/new`;
    }
  }

  downloadCommande(numCommande: string): void {
    this._commandeService.getFactureCommande(numCommande).subscribe((response: Blob) => {
      const blobUrl = window.URL.createObjectURL(response);
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      const fileName = `Facture_${numCommande}.pdf`;

      this.pdfDialog.open(PdfViewerDialogComponent, {
        width: '80%',
        height: '80%',
        data: { pdfUrl: safeUrl, fileName }
      });
    });
  }

  downloadCertif(numCommande: string): void {
    this._commandeService.getCertificat(numCommande).subscribe((response: Blob) => {
      const blobUrl = window.URL.createObjectURL(response);
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      const fileName = `Certificat(s)_${numCommande}.pdf`;

      this.pdfDialog.open(PdfViewerDialogComponent, {
        width: '80%',
        height: '80%',
        data: { pdfUrl: safeUrl, fileName }
      });
    });
  }
}
