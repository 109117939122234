import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrl: './connexion.component.css'
})
export class ConnexionComponent {
  isLoggedIn = false;
  roles: string[] = [];
  isLoginFailed = false;
  loginForm!: FormGroup;
  errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private dialog: MatDialog,
  ) {
    this.initFormConnexion();
  }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().Utilisateur?.roles;
      this.reloadPage();
    }
  }

  initFormConnexion() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe({
        next: (response) => {
          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.roles = this.tokenStorage.getUser().Utilisateur?.roles;
          this.reloadPage();
        },
        error: (err) => {
          this.errorMessage = this.getErrorMessage(err);
          this.isLoginFailed = true;
          this.dialog.open(SuccessDialogComponent, {
            data: {
              message: this.errorMessage
            }
          });
        }
      });
    }
  }

  getErrorMessage(error: any): string {
    switch (error.status) {
      case 400:
        return 'Requête invalide';
      case 405:
        return 'Nom d\'utilisateur ou mot de passe incorrect';
      case 406:
        return 'Votre profil est désactivé';
      default:
        return 'Erreur de serveur. Veuillez réessayer plus tard.';
    }
  }

  reloadPage() {
    window.location.replace('/commandes');
  }
}
