import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Commande } from '../z-model/commande';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class CommandeService {

  apiUrl = environment.baseUrl + 'api/commandes';

  constructor(private _http: HttpClient) { }

  getCommandes(): Observable<Commande[]> {
    return this._http.get<Commande[]>(this.apiUrl);
  }

  getCommande(idCommande: string): Observable<Commande> {
    return this._http.get<Commande>(this.apiUrl + '/' + idCommande);
  }

  addCommande(commande: Commande): Observable<Commande> {
    return this._http.post<Commande>(this.apiUrl, JSON.stringify(commande), { headers: headers });
  }

  updateCommande(commande: any): Observable<Commande> {
    return this._http.post<Commande>(this.apiUrl + '/update', JSON.stringify(commande), { headers: headers });
  }

  getFactureCommande(idCommande: string) {
    return this._http.get(this.apiUrl + '/genererFacture/' + idCommande, {
      responseType: 'blob'
    });
  }

  getCertificat(idCommande: string) {
    return this._http.get(this.apiUrl + '/genererCertificatAuthenticite/' + idCommande, {
      responseType: 'blob'
    });
  }

  getFacturesCommandes(idsCommande: string[]): Observable<Blob> {
    return this._http.post(this.apiUrl + '/genererZipListeFactures', idsCommande, {
      responseType: 'blob'
    });
  }
}
