import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { StatutCommande } from '../z-model/statut-commande';

@Injectable({
  providedIn: 'root'
})
export class StatutCommandeService {

  apiUrl = environment.baseUrl + 'api/statut-commandes';

  constructor(private _http: HttpClient) { }

  getStatutCommande(): Observable<StatutCommande[]> {
    return this._http.get<StatutCommande[]>(this.apiUrl);
  }
}
