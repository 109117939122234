<div class="list-container">
    <p class="app-titre-component">Gestion des utilisateurs</p>

    <div class="container">
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchNomUtilisateur" (input)="filterUtilisateurs()"
                placeholder="Nom"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchPrenomUtilisateur" (input)="filterUtilisateurs()"
                placeholder="Prénom"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchUsername" (input)="filterUtilisateurs()"
                placeholder="Identifiant"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="uniform-size">
            <input matInput [(ngModel)]="searchEmailUtilisateur" (input)="filterUtilisateurs()"
                placeholder="Adresse mail"/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="uniform-size">
            <mat-select [(ngModel)]="searchEtatUtilisateur"  placeholder="Etat" (selectionChange)="filterUtilisateurs()">
                <mat-option value="">Aucun choix</mat-option>
                <mat-option [value]="true">Actif</mat-option>
                <mat-option [value]="false">Non actif</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button class="uniform-size" (click)="openUtilisateurForm()">Ajouter un utilisateur</button>
    </div>

    <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8 mat-table">
        <ng-container matColumnDef="nom">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nom </mat-header-cell>
            <mat-cell *matCellDef="let utilisateur"> {{utilisateur.nom}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="prenom">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </mat-header-cell>
            <mat-cell *matCellDef="let utilisateur"> {{utilisateur.prenom}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="magasin">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Magasin </mat-header-cell>
            <mat-cell *matCellDef="let utilisateur"> {{utilisateur.magasin?.nom}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Identifiant </mat-header-cell>
            <mat-cell *matCellDef="let utilisateur"> {{utilisateur.username}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Adresse mail </mat-header-cell>
            <mat-cell *matCellDef="let utilisateur"> {{utilisateur.email}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="estActif">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Etat </mat-header-cell>
            <mat-cell *matCellDef="let utilisateur"> {{utilisateur.estActif == true? 'Actif' : 'Non actif'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let utilisateur">
                <button mat-icon-button (click)="openUtilisateurForm(utilisateur)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="utilisateur.estActif && utilisateurIdConnecte !== utilisateur.id" mat-icon-button (click)="changerEtatProfile(utilisateur)" matTooltip="Désactiver ce profil">
                    <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="!utilisateur.estActif" mat-icon-button (click)="changerEtatProfile(utilisateur)" matTooltip="Activer ce profil">
                    <mat-icon>restore</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>