<div class="modal-content">
    <mat-dialog-content class="modal">
        <h2 mat-dialog-title class="modal-header">{{ data.id ? 'Modification de client' : 'Ajout d\'un Client' }}</h2>
        <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label>Nom</mat-label>
                    <input matInput formControlName="nom" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label>Prénom</mat-label>
                    <input matInput formControlName="prenom" required>
                </mat-form-field>
            </div>
            <div class="form-row">
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label>Téléphone</mat-label>
                    <input matInput formControlName="telephone">
                </mat-form-field>
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
            <div class="form-row">
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label>Adresse</mat-label>
                    <input matInput formControlName="adresse">
                </mat-form-field>
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label>Réseau social</mat-label>
                    <input matInput formControlName="reseauSocial">
                </mat-form-field>
            </div>
            <div class="form-row">
                <mat-form-field appearance="outline" class="mat-form-field">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description"></textarea>
                </mat-form-field>
            </div>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onCancel()">Annuler</button>
                <button mat-button [disabled]="!clientForm.valid" type="submit">Enregistrer</button>
            </div>
        </form>
    </mat-dialog-content>
</div>