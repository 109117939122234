import { HttpClient, HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';

const SKIP_INTERCEPTOR = new HttpContextToken(() => false);
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl = environment.baseUrl + 'api/auth/authenticate';

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorageService
  ) {}

  login(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      context: new HttpContext().set(SKIP_INTERCEPTOR, true)
    };
    return this.http.post<any>(this.apiUrl, data, httpOptions).pipe(
      tap(response => {
        console.log(response)
        if (response.token) {
          this.tokenStorage.saveToken(response.token);
          this.tokenStorage.saveDecodedUserFromToken(response.token);
        }
      })
    );
  }
}
