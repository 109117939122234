<mat-toolbar class="mat-elevation-z8">

    <div *ngIf="isLoggedIn">
        <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
            <mat-icon *ngIf="!sidenav.opened">
                menu
            </mat-icon>
            <mat-icon *ngIf="sidenav.opened">
                close
            </mat-icon>
        </button>
    </div>

    <div>
        <img class="appLogoImg" alt="Oryno Jewellery Logo" src="assets/orynoJewelleryLogo.png"/>
        <!-- <span class="companyName">Oryno Jewellery</span> -->
    </div>

    <div class="loginlogout">
        <button mat-button *ngIf="!isLoggedIn" routerLink="connexion">
            <mat-icon>login</mat-icon>
            <span class="coDeco">Connexion </span>
        </button>

        <button mat-button *ngIf="isLoggedIn" (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span class="coDeco">Déconnexion </span>
        </button>
    </div>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav  style="display: none;" [ngStyle]="{'display':isLoggedIn == false ? 'none' : 'block' }"  #sidenav="matSidenav" class="mat-elevation-z8">
        <app-menu></app-menu>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content invisible-scrollbar">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>