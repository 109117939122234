import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Categorie } from '../../z-model/categorie';
import { Modele } from '../../z-model/modele';
import { CategorieService } from '../../_services/categorie.service';
import { ModeleService } from '../../_services/modele.service';
import { ModeleDetailsComponent } from '../modele-details/modele-details.component';
import { ModeleFormComponent } from '../modele-form/modele-form.component';
import { SousCategorieService } from '../../_services/sous-categorie.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { SousCategorie } from '../../z-model/sous-categorie';
import { Role } from '../../z-model/role';

@Component({
  selector: 'app-modele-list',
  templateUrl: './modele-list.component.html',
  styleUrl: './modele-list.component.css'
})
export class ModeleListComponent implements OnInit {
  filteredModeles: Modele[] = [];
  paginatedCards: Modele[] = [];
  selectedCategory: string = '';
  selectedSousCategory: string = '';
  categories: Categorie[] = [];
  sousCategories: SousCategorie[] = [];
  searchNomModele: string = '';
  searchQuery: string = '';
  currentPage: number = 0;
  modeles: Modele[] = [];
  totalCards!: number;
  pageSize = 12;
  Role = Role;
  user: any;

  constructor(
    private _sousCategoriesService: SousCategorieService,
    private tokenStorageService: TokenStorageService,
    private _categorieService: CategorieService,
    private _modelesService: ModeleService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadModeles();
    this.loadCategories();
    this.loadSousCategories();
    this.user = this.tokenStorageService.getUser();
  }

  loadModeles(): void {
    this._modelesService.getModeles().subscribe(modeles => {
      this.modeles = modeles;
      this.filteredModeles = modeles;
      this.totalCards = modeles.length;
      this.updatePaginatedCards();
    });
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  loadSousCategories() {
    this._sousCategoriesService.getSousCategories().subscribe(data => {
      this.sousCategories = data;
    });
  }

  filterModeles(): void {
    this.filteredModeles = this.modeles.filter(modele => {
      return (
        (!this.searchNomModele || modele.nomModel.toLowerCase().includes(this.searchNomModele.toLowerCase())) &&
        (!this.selectedCategory || modele.categorie.id === this.selectedCategory) &&
        (!this.selectedSousCategory || modele.sousCategorie.id === this.selectedSousCategory) &&
        (!this.searchQuery || modele.description.toLowerCase().includes(this.searchQuery.toLowerCase()))
      );
    });
    this.totalCards = this.filteredModeles.length;  
    this.currentPage = 0;
    this.updatePaginatedCards();
  }

  updatePaginatedCards(): void {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedCards = this.filteredModeles.slice(startIndex, endIndex);
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePaginatedCards();
  }

  openModeleDialog(modele: Modele): void {
    this.dialog.open(ModeleDetailsComponent, {
      width: '400px',
      data: modele
    });
  }

  getImageUrl(photo: string): string {
    return this._modelesService.getImageUrl(photo);
  }

  openModeleForm(modele: Modele | null) {
    const dialogRef = this.dialog.open(ModeleFormComponent, {
      width: '600px',
      data: { modele: modele ? modele : new Modele() }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (modele) {
          const index = this.modeles.findIndex(m => m.id === result.id);
          if (index !== -1) {
            this.modeles[index] = result;
          }
        } else {
          this.modeles.push(result);
        }
        this.filterModeles();
      }
    });
  }
}
