import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatTableModule } from '@angular/material/table';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { ModificationCategorieComponent } from './parametrages/modification-categorie/modification-categorie.component';
import { ArticleSearchDialogComponent } from './commande/article-search-dialog/article-search-dialog.component';
import { ArtcileMouvementComponent } from './articles/artcile-mouvement/artcile-mouvement.component';
import { UtilisateurListComponent } from './utilisateur/utilisateur-list/utilisateur-list.component';
import { UtilisateurFormComponent } from './utilisateur/utilisateur-form/utilisateur-form.component';
import { UtilisateurEditComponent } from './utilisateur/utilisateur-edit/utilisateur-edit.component';
import { PdfViewerDialogComponent } from './pdf-viewer-dialog/pdf-viewer-dialog.component';
import { ModeleDetailsComponent } from './modele/modele-details/modele-details.component';
import { CommandeListComponent } from './commande/commande-list/commande-list.component';
import { CommandeFormComponent } from './commande/commande-form/commande-form.component';
import { AuthInterceptor, authInterceptorProviders } from './_helpers/auth.interceptor';
import { httpErrorHandlerProviders } from './_helpers/http-error-handler-interceptor';
import { ArticleListComponent } from './articles/article-list/article-list.component';
import { ArticleFormComponent } from './articles/article-form/article-form.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { ClientListComponent } from './client/client-list/client-list.component';
import { ClientFormComponent } from './client/client-form/client-form.component';
import { ModeleListComponent } from './modele/modele-list/modele-list.component';
import { ModeleFormComponent } from './modele/modele-form/modele-form.component';
import { StockMagasinComponent } from './stock-magasin/stock-magasin.component';
import { StatistiquesComponent } from './statistiques/statistiques.component';
import { ParametragesComponent } from './parametrages/parametrages.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConnexionComponent } from './connexion/connexion.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthGuardService } from './_services/auth-guard.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from './app-routing.module';
import {  MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MenuComponent } from './menu/menu.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AppComponent } from './app.component';
import * as moment from 'moment';
import 'moment/locale/fr';
import { StatistiquesPayementsComponent } from './statistiques-payements/statistiques-payements.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
 
registerLocaleData(localeFr, 'fr');
moment.locale('fr');

@NgModule({
  declarations: [
    AppComponent,
    ConnexionComponent,
    CommandeListComponent,
    CommandeFormComponent,
    ClientListComponent,
    ClientFormComponent,
    ArticleListComponent,
    ArticleFormComponent,
    MenuComponent,
    ModeleListComponent,
    ModeleFormComponent,
    ModeleDetailsComponent,
    StockMagasinComponent,
    SuccessDialogComponent,
    UtilisateurListComponent,
    UtilisateurFormComponent,
    UtilisateurEditComponent,
    PdfViewerDialogComponent,
    ArtcileMouvementComponent,
    ParametragesComponent,
    ModificationCategorieComponent,
    StatistiquesComponent,
    ArticleSearchDialogComponent,
    StatistiquesPayementsComponent,
    ConfirmDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatOptionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule
  ],
  providers: [
    AuthGuardService,
    authInterceptorProviders,
    httpErrorHandlerProviders,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    provideAnimationsAsync(),
    MatDatepickerModule,
    MatNativeDateModule,
    { provide: LOCALE_ID, useValue: "fr-FR" },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
        week: {
          dow: 1,
        },
      },
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
