<div class="modal-content">
  <h2 mat-dialog-title class="modal-header">Informations artcile</h2>
  <mat-dialog-content>
    <form [formGroup]="articleForm" (ngSubmit)="onSubmit()" novalidate>
      <div *ngIf="article.id" class="row image-container">
        <img [src]="getImageUrl(article.modele.photo)" alt="{{ article.modele.description }}" class="fixed-size-image"/>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <mat-form-field formGroupName="modele" appearance="outline" class="w-100">
            <mat-label>Nom du modèle</mat-label>
            <mat-select formControlName="id">
              <mat-option *ngFor="let modele of modeles" [value]="modele.id"> {{ modele.nomModel }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-3 d-flex align-items-end">
          <mat-form-field formGroupName="statut" appearance="outline" class="w-100">
            <mat-label>Site de l'article</mat-label>
            <mat-select formControlName="id">
              <mat-option *ngFor="let article of statutsArticle" [value]="article.id"> {{ article.libelle }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="mb-3" [ngClass]="{'col-md-4': isPrixRequired, 'col-md-6': !isPrixRequired}">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Poids</mat-label>
            <input matInput type="number" formControlName="poids">
            <mat-error *ngIf="articleForm.get('poids')?.hasError('required')">Le poids est requis</mat-error>
          </mat-form-field>
        </div>
        <div class="mb-3" [ngClass]="{'col-md-4': isPrixRequired, 'col-md-6': !isPrixRequired}">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Caracts</mat-label>
            <input matInput formControlName="caracts">
          </mat-form-field>
        </div>
        <div *ngIf="isPrixRequired" class="mb-3 d-flex align-items-end" [ngClass]="{'col-md-4': isPrixRequired, 'col-md-6': !isPrixRequired}">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Prix</mat-label>
            <input matInput type="number" formControlName="prix">
            <mat-error *ngIf="articleForm.get('modele.prix')?.hasError('required')">Le prix est requis</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="custom-actions">
      <button mat-button (click)="onCancel()">Annuler</button>
      <button mat-button [disabled]="!articleForm.valid" (click)="onSubmit()">Enregistrer</button>
    </div>
  </mat-dialog-content>
</div>