import { Component, Inject, OnInit } from '@angular/core';
import { SuccessDialogComponent } from '../../success-dialog/success-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StatutArticleService } from '../../_services/statut-article.service';
import { SousCategorieService } from '../../_services/sous-categorie.service';
import { CategorieService } from '../../_services/categorie.service';
import { ModeleService } from '../../_services/modele.service';
import { SousCategorie } from '../../z-model/sous-categorie';
import { StatutArticle } from '../../z-model/statut-article';
import { Categorie } from '../../z-model/categorie';
import { Modele } from '../../z-model/modele';

@Component({
  selector: 'app-modele-form',
  templateUrl: './modele-form.component.html',
  styleUrl: './modele-form.component.css'
})
export class ModeleFormComponent implements OnInit {
  statutsArticle: StatutArticle[] = [];
  sousCategories: SousCategorie[] = [];
  articleFormulaire!: FormGroup;
  categories: Categorie[] = [];
  modelFormulaire!: FormGroup;
  selectedFile: File | null = null;
  isEditing!: boolean;
  modele!: Modele;

  constructor(
    private _sousCategoriesService: SousCategorieService,
    private _statutArticleService: StatutArticleService,
    public dialogRef: MatDialogRef<ModeleFormComponent>,
    private _categorieService: CategorieService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _modelesService: ModeleService,
    private _formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.createFormModele();
    this.createFormArticle();
    this.modele = data.modele;
    this.isEditing = data && data.modele.id !== '' && typeof data.modele.id !== 'undefined';
    if (this.isEditing) {
      this.patchForm(this.modele);
    }
  }

  patchForm(modele: any) {
    this.modelFormulaire.patchValue({
      id: modele.id,
      categorie: {
        id: modele.categorie.id
      },
      sousCategorie: {
        id: modele.sousCategorie.id
      },
      nomModel: modele.nomModel,
      description: modele.description,
      photo: modele.photo
    });
  }
  
  ngOnInit(): void {
    this.loadListeStatutsArticle();    
    this.loadSousCategories();
    this.loadCategories();
  }

  loadListeStatutsArticle() {
    this._statutArticleService.getStatutsArticle().subscribe(data => {
      this.statutsArticle = data;
    });
  }

  createFormModele() {
    this.modelFormulaire = this._formBuilder.group({
      id: '',
      categorie: this._formBuilder.group({
        id: ['', Validators.required]
      }),
      sousCategorie: this._formBuilder.group({
        id: ['', Validators.required]
      }),
      nomModel: ['', Validators.required],
      description: ['', Validators.maxLength(255)],
      photo: ['']
    });
  }

  createFormArticle() {
    this.articleFormulaire = this._formBuilder.group({
      articles: this._formBuilder.array([
        this._formBuilder.group({
          modele: this._formBuilder.group({
            id: '',
          }),
          statutId: ['', Validators.required],
          quantite: [0, [Validators.required, Validators.min(0)]],
        })
      ])
    });
  }

  loadCategories() {
    this._categorieService.getCategories().subscribe(data => {
      this.categories = data;
    });
  }

  loadSousCategories() {
    this._sousCategoriesService.getSousCategories().subscribe(data => {
      this.sousCategories = data;
    });
  }

  get articles() {
    return this.articleFormulaire.get('articles') as FormArray;
  }

  addArticle() {
    this.articles.push(this._formBuilder.group({
      modele: this._formBuilder.group({
        id: '',
      }),
      statutId: ['', Validators.required],
      quantite: [0, [Validators.required, Validators.min(0)]],
    }));
  }

  removeArticle(index: number) {
    this.articles.removeAt(index);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    } else {
      this.selectedFile = null;
    }
  }

  onSubmit() {
    if (this.modelFormulaire.valid) {
      this._modelesService.addModele(this.modelFormulaire.value).subscribe({
        next: (modele) => {
          if (this.selectedFile) {
            const formData = new FormData();
            formData.append('photo', this.selectedFile, this.selectedFile.name);
            this._modelesService.uploadPhoto(formData, modele.id).subscribe({
              next: (response) => {
                const parsedResponse = JSON.parse(response);
                const photoUrl = parsedResponse.path;
                this.modelFormulaire.patchValue({
                  photo: photoUrl,
                });
                this.modele = this.modelFormulaire.value;
                this.modele.id = modele.id;
                this._modelesService.addModele(this.modele).subscribe({
                  next: (modele) => {
                    console.log('Mise à jour du modèle avec succès');
                  },
                  error: (error) => {
                    console.error('Erreur lors de la mise à jour du modèle', error);
                  },
                });
              },
              error: (error) => {
                this.dialog.open(SuccessDialogComponent, {
                  data: {
                    message: 'Échec du téléchargement de la photo. Vérifiez le fichier et réessayez.'
                  }
                });
              },
            });
          }
          this.modele = modele;
        },
        error: (error) => {
          this.dialog.open(SuccessDialogComponent, {
            data: {
              message: 'Une erreur s\'est produite lors de la création du modèle. Le modèle n\'a pas été mis à jour.'
            }
          });
        },
      });
      this.dialogRef.close(this.modelFormulaire.value);
    } else {
      this.modelFormulaire.markAllAsTouched();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
